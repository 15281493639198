import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosConfig';
import { useParams, useNavigate } from 'react-router-dom';
import './DealEntryForm.css';

const DealEditForm = ({ keycloak }) => {
  const [form, setForm] = useState({
    property: '',
    discountPolicy: '',
    customerName: '',
    birthDate: '',
    dealDate: '',
    introducer: '',
    dealAmount: '',   // 成交金额
    amountPaid: '',   // 已付款
    amountDue: '',    // 未付款
    followUpPlan: '', // 跟进计划
  });

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDeal = async () => {
      try {
        const response = await axiosInstance.get(`/deals/${id}`);
        setForm(response.data);
      } catch (error) {
        console.error('Error fetching deal:', error);
      }
    };

    fetchDeal();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm(prevForm => {
      const updatedForm = { ...prevForm, [name]: value };

      // 计算未付款金额
      if (name === 'dealAmount' || name === 'amountPaid') {
        const dealAmount = parseFloat(updatedForm.dealAmount) || 0;
        const amountPaid = parseFloat(updatedForm.amountPaid) || 0;
        updatedForm.amountDue = dealAmount - amountPaid; // 计算未付款
      }

      return updatedForm;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put(`/deals/${id}`, form); // 发送更新请求
      alert('成交记录更新成功');
      navigate('/deal');
    } catch (error) {
      console.error('Error updating deal:', error);
      alert('更新失败，请重试');
    }
  };

  return (
    <div className="deal-edit-form">
      <h2>编辑成交记录</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>成交房源:</label>
          <input
            name="property"
            placeholder="成交房源"
            value={form.property}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>优惠政策:</label>
          <input
            name="discountPolicy"
            placeholder="优惠政策"
            value={form.discountPolicy}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>客户姓名:</label>
          <input
            name="customerName"
            placeholder="客户姓名"
            value={form.customerName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>出生日期:</label>
          <input
            name="birthDate"
            type="date"
            value={form.birthDate}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>成交日期:</label>
          <input
            name="dealDate"
            type="date"
            value={form.dealDate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>介绍人:</label>
          <input
            name="introducer"
            placeholder="介绍人"
            value={form.introducer}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>成交金额:</label>
          <input
            name="dealAmount"
            type="number"
            placeholder="成交金额"
            value={form.dealAmount}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>已付款:</label>
          <input
            name="amountPaid"
            type="number"
            placeholder="已付款"
            value={form.amountPaid}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>未付款:</label>
          <input
            name="amountDue"
            type="number"
            placeholder="未付款"
            value={form.amountDue}
            readOnly // 不允许直接手动输入
          />
        </div>
        <div className="form-group">
          <label>跟进计划:</label>
          <textarea
            name="followUpPlan"
            placeholder="跟进计划"
            value={form.followUpPlan}
            onChange={handleChange}            
          />
        </div>
        <button type="submit">更新</button>
      </form>
    </div>
  );
};

export default DealEditForm;
