// CustomerForm.js
import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../../axiosConfig';
import './CustomerForm.css';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

// 表单验证规则
const validationSchema = Yup.object({
    identityCard: Yup.string().matches(/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, '请输入有效的身份证号码').required('身份证号是必填项'),
    name: Yup.string().max(100, '姓名最多100个字符').required('姓名是必填项'),
    phone: Yup.string().matches(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, '请输入有效的电话号码').required('电话号码是必填项'),
    channel: Yup.string().max(100, '渠道名称最多100个字符').required('渠道是必填项'),
});

const CustomerForm = ({keycloak}) => {
    const navigate = useNavigate();
    const [projectGroupsName, setProjectGroupsName] = useState([]);
    const [initialValues, setInitialValues] = useState({
        identityCard: '',
        name: '',
        phone: '',
        channel: '非项目渠道',
    });

    const fetchProjectGroupsName = useCallback(() => {
        const userGroups = keycloak.tokenParsed?.group;
        const tempProjectGroupsName = userGroups
            .filter(group =>
                group.startsWith(config.projectGroupName) && group !== config.projectGroupName && group.split('/').length === config.projectGroupName.split('/').length + 1
            )
            .map(group => group.slice(config.projectGroupName.length + 1));
        setProjectGroupsName(tempProjectGroupsName);

        // Set initial channel once project groups are loaded
        if (tempProjectGroupsName.length > 0) {
            setInitialValues((prevValues) => ({
                ...prevValues,
                channel: tempProjectGroupsName[0],
            }));
        }
    }, [keycloak]);

    useEffect(() => {
        fetchProjectGroupsName();
    }, [fetchProjectGroupsName]);

    return (
        <div className="form-container">
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}  // Reinitialize form when initial values change
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    console.log(values); // Debug: check submitted values
                    try {
                        await axiosInstance.post('/customers', values);
                        resetForm();
                        navigate('/CustomerList');
                    } catch (error) {
                        console.error('提交数据时出错', error);
                        alert('提交失败，请重试。');
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form className="customer-form">
                        <h2>客户信息录入</h2>

                        <div className="form-group">
                            <label htmlFor="identityCard">身份证号</label>
                            <Field type="text" name="identityCard" className="form-field" />
                            <ErrorMessage name="identityCard" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="name">姓名</label>
                            <Field type="text" name="name" className="form-field" />
                            <ErrorMessage name="name" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone">电话号码</label>
                            <Field type="text" name="phone" className="form-field" />
                            <ErrorMessage name="phone" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="channel">渠道</label>
                            <Field as="select" name="channel" className="form-field">                           
                                {projectGroupsName.map((projectGroupName) => (
                                    <option key={projectGroupName} value={projectGroupName}>{projectGroupName}</option>
                                ))}
                                <option value="非项目渠道">非项目渠道</option>
                            </Field>
                            <ErrorMessage name="channel" component="div" className="error-message" />
                        </div>

                        <button type="submit" disabled={isSubmitting} className="submit-button">
                            {isSubmitting ? '提交中...' : '提交'}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CustomerForm;
