import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../axiosConfig';
import './LeadDistributionList.css';
import config from '../../config';

const LeadDistributionList = ({ keycloak }) => {
    const [leadDistributions, setLeadDistributions] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [editingDistributionId, setEditingDistributionId] = useState(null);
    const [editedDistribution, setEditedDistribution] = useState(null);
    const [salesTeams, setSalesTeams] = useState(null);
    const [selectedSalesTeam, setSelectedSalesTeam] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [inputPage, setInputPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [projectGroup, setProjectGroup] = useState('');
    const [salesTeam, setSalesTeam] = useState('');
    const [planner, setPlanner] = useState('');
    const [userRole, setUserRole] = useState('');
    const [availableProjectGroups, setAvailableProjectGroups] = useState('');
    const [availableSalesTeams, setAvailableSalesTeams] = useState('');
    const [availablePlanners, setAvailablePlanners] = useState('');
    const [validityOfLeads, setValidityOfLeads] = useState('');
    const parentGroupId = config.salesTeamsGroupId;
    useEffect(() => {
        // 从 keycloak 中提取用户角色和可用的筛选选项
        const userGroups = keycloak.tokenParsed?.group || [];
        let role = '';
        if (userGroups.includes('/数据中心')) {
            role = 'dataCenter';
        } else if (userGroups.some(g => g.startsWith('/项目组/'))) {
            role = 'projectGroup';
            setProjectGroup(userGroups.find(g => g.startsWith('/项目组/')).split('/')[2]);
        } else if (userGroups.some(g => g.includes('/小组长'))) {
            role = 'salesManager';
            setSalesTeam(userGroups.find(g => g.includes('/小组长')).split('/')[2]);
        } else if (userGroups.some(g => g.includes('/规划师'))) {
            role = 'planner';
        }
        setUserRole(role);

        // 获取可用的筛选选项
        fetchAvailableOptions();
    }, [keycloak]);

    const fetchAvailableOptions = async () => {
        try {
            const response = await axiosInstance.get('/customer-leads/available-options');
            setAvailableProjectGroups(response.data.projectGroups);
            setAvailableSalesTeams(response.data.salesDepartments);
            setAvailablePlanners(response.data.planners);
        } catch (error) {
            console.error('获取可用选项失败', error);
        }
    };
    const fetchLeadDistributions = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/lead-distribution', {
                params: {
                    page: page - 1,
                    startDate: startDate || undefined,
                    endDate: endDate || undefined,
                    searchTerm: searchTerm || undefined,
                    projectGroup: projectGroup || undefined,
                    salesTeam: salesTeam || undefined,
                    planner: planner || undefined,
                    validityOfLeads: validityOfLeads || undefined
                },
            });
            setLeadDistributions(response.data.content || []);
            setTotalPages(response.data.totalPages);
            setInputPage(page);
            setTotalItems(response.data.totalElements);
        } catch (error) {
            console.error('获取分配记录失败', error);
        }

    }, [page, searchTerm, startDate, endDate, projectGroup, salesTeam, planner, validityOfLeads]);

    const fetchGroupMembers = async (selectedGroupId) => {
        if (selectedSalesTeam) {
            try {
                const { data: groupId } = await axiosInstance.get(`/keycloak/admin/groupIdByName`, {
                    params: {
                        parentGroupId: selectedGroupId,
                        groupName: '规划师'
                    }
                });
                const response = await axiosInstance.get('/keycloak/admin/groupMembers', {
                    params: {
                        groupId: groupId
                    }
                });
                setSelectedSalesTeam(response.data);
            } catch (error) {
                console.error('获取销售部门列表时出错', error);
                // alert('获取销售部门列表失败，请刷新页面重试')
            }
        }
    };

    useEffect(() => {
        fetchLeadDistributions();
    }, [fetchLeadDistributions]);


    const handleJumpToPage = () => {
        if (inputPage >= 1 && inputPage <= totalPages) {
            setPage(inputPage);
        } else {
            alert(`请输入有效的页码（1到${totalPages}）`);
        }
    };
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const handleEdit = async (distributionId) => {
        setEditingDistributionId(distributionId);
        const distribution = leadDistributions.find((dist) => dist.id === distributionId);
        try {
            const response = await axiosInstance.get('/keycloak/admin/subGroups', {
                params: {
                    groupId: parentGroupId
                }
            });
            setSalesTeams(response.data);
        } catch (error) {
            console.error('获取销售部门列表时出错', error);
            alert('获取销售部门列表失败，请刷新页面重试')
        }
        try {
            const { data: salesTeamGroupId } = await axiosInstance.get(`/keycloak/admin/groupIdByName`, {
                params: {
                    parentGroupId: parentGroupId,
                    groupName: distribution.salesTeam
                }
            });
            const { data: groupId } = await axiosInstance.get(`/keycloak/admin/groupIdByName`, {
                params: {
                    parentGroupId: salesTeamGroupId,
                    groupName: '规划师'
                }
            });
            const membersResponse = await axiosInstance.get('/keycloak/admin/groupMembers', {
                params: {
                    groupId: groupId
                }
            });
            setSelectedSalesTeam(membersResponse.data);
            setEditedDistribution(distribution);
        } catch (error) {
            console.error('在 handleEdit 中出错', error);
            alert('操作失败，请重试。');
        }
    };

    const handleFieldChange = async (field, value) => {
        const updatedDistribution = { ...editedDistribution, [field]: value || null };
        setEditedDistribution(updatedDistribution);

        if (field === 'salesTeam') {
            try {
                const { data: groupId } = await axiosInstance.get(`/keycloak/admin/groupIdByName`, {
                    params: {
                        parentGroupId: parentGroupId,
                        groupName: value
                    }
                });
                if (groupId) {
                    await fetchGroupMembers(groupId);
                }
            } catch (error) {
                console.error('获取销售团队成员时出错', error);
            }
        }
    };

    const handleSave = async () => {
        try {
            await axiosInstance.put(`/lead-distribution/${editingDistributionId}`, editedDistribution);
            fetchLeadDistributions();
            setEditingDistributionId(null);
        } catch (error) {
            console.error('保存修改时出错', error);
        }
    };

    const handleDelete = async (distributionId) => {
        if (window.confirm('是否删除该条记录')) {
            try {
                await axiosInstance.delete(`/lead-distribution/${distributionId}`);
                fetchLeadDistributions();
            } catch (error) {
                console.error('删除时出错', error);
            }
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        let startPage = Math.max(1, page - 4);
        let endPage = Math.min(totalPages, page + 5);

        if (endPage - startPage < 9) {
            startPage = Math.max(1, endPage - 9);
            endPage = Math.min(totalPages, startPage + 9);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={i === page ? 'active' : ''}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }
        return pageNumbers;
    };

    return (
        <div className="list-container">
            <h1>客资分配列表</h1>
            <div className="filter-container">
                <div>
                    <input
                        type="text"
                        placeholder="根据ID、客资ID、姓名、电话搜索"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <input
                        type="datetime-local"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                        type="datetime-local"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                <div>
                {(userRole === 'dataCenter' || userRole === 'salesManager' || userRole === 'planner') && (
                    <select value={projectGroup} onChange={(e) => setProjectGroup(e.target.value)}>
                        <option value="">全部项目组</option>
                        {availableProjectGroups && (availableProjectGroups?.map(group => (
                            <option key={group} value={group}>{group}</option>
                        )))}
                    </select>
                )}
                {(userRole === 'dataCenter' || userRole === 'projectGroup') &&
                    (<select value={salesTeam} onChange={(e) => setSalesTeam(e.target.value)}>
                        <option value="">全部销售部门</option>
                        {availableSalesTeams && (availableSalesTeams?.map(dept => (
                            <option key={dept} value={dept}>{dept}</option>
                        )))}
                    </select>
                    )}
                {(userRole === 'dataCenter' || userRole === 'projectGroup' || userRole === 'salesManager') &&
                    (<select value={planner} onChange={(e) => setPlanner(e.target.value)}>
                        <option value="">全部规划师</option>
                        {availablePlanners && (availablePlanners?.map(planner => (
                            <option key={planner} value={planner}>{planner ? planner : "未分配"}</option>
                        )))}
                    </select>
                    )}

                <select value={validityOfLeads} onChange={(e) => setValidityOfLeads(e.target.value)}>
                    <option value="">全部客资有效性</option>
                    <option value="true">有效</option>
                    <option value="false">无效</option>
                </select>
                </div>
            </div>

            <div className="table-scrollable">
                <table className="distribution-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>分配时间</th>
                            <th>客资ID</th>
                            <th>客户姓名</th>
                            <th>客户电话</th>
                            <th>项目组</th>
                            <th>销售团队</th>
                            <th>规划师</th>
                            {(userRole === 'dataCenter' || userRole === 'salesManager') && (<th>操作</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {leadDistributions.map((distribution) => (
                            <tr key={distribution.id}>
                                <td>{distribution.id}</td>
                                <td>
                                    {new Date(distribution.date).toLocaleString()}
                                </td>
                                <td>{distribution.customerLead.id}</td>
                                <td>{distribution.customerLead.name}</td>
                                <td>{distribution.customerLead.phone}</td>
                                <td>{distribution.customerLead.projectGroup}</td>
                                <td>
                                    {(editingDistributionId === distribution.id && userRole === 'dataCenter') ? (
                                        <select
                                            value={editedDistribution?.salesTeam || ''}
                                            onChange={(e) => handleFieldChange('salesTeam', e.target.value)}
                                            className="edit-input"
                                        >
                                            <option value="" disabled>选择销售团队</option>
                                            {salesTeams?.map((team) => (
                                                <option key={team.id} value={team.name}>
                                                    {team.name}
                                                </option>
                                            )) || ''}
                                        </select>
                                    ) : (
                                        distribution.salesTeam
                                    )}
                                </td>
                                <td>
                                    {editingDistributionId === distribution.id ? (
                                        <select
                                            value={editedDistribution?.planner}
                                            onChange={(e) => handleFieldChange('planner', e.target.value)}
                                            className="edit-input"
                                        >
                                            <option value="未分配">选择规划师</option>
                                            {selectedSalesTeam?.map((team) => (
                                                <option key={team.id} value={team.lastName + team.firstName}>
                                                    {`${team.lastName}${team.firstName}`}
                                                </option>
                                            )) || ''}
                                        </select>
                                    ) : (
                                        distribution?.planner || '未分配'
                                    )}
                                </td>
                                {(userRole === 'dataCenter' || userRole === 'salesManager') && (<td>
                                    {editingDistributionId === distribution.id ? (
                                        <>
                                            <button onClick={handleSave} className="action-button">保存</button>
                                            <button onClick={() => setEditingDistributionId(null)} className="action-button">取消</button>
                                        </>
                                    ) : (
                                        <>
                                            {(userRole === 'dataCenter' || userRole === 'salesManager') && (<button onClick={() => handleEdit(distribution.id)} className="action-button">编辑</button>)}
                                            {userRole === 'dataCenter' && (<button onClick={() => handleDelete(distribution.id)} className="action-button">删除</button>)}
                                        </>
                                    )}
                                </td>)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination-container">
                <div className="pagination">
                    <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                        上一页
                    </button>
                    {renderPageNumbers()}
                    <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
                        下一页
                    </button>
                </div>
                <div className="pagination-info">
                    <span>共 {totalItems} 条记录, {totalPages} 页</span>
                    <div className="page-jump">
                        <input
                            type="number"
                            min="1"
                            max={totalPages}
                            value={inputPage}
                            onChange={(e) => setInputPage(Number(e.target.value) || 1)}
                        />
                        <button onClick={handleJumpToPage}>跳转</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeadDistributionList;
