// PendingCustomerLeadList.js
import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../axiosConfig';
import './PendingCustomerLeadList.css';

const PendingCustomerLeadList = ({ keycloak }) => {
    const [pendingLeads, setPendingLeads] = useState([]);
    const [filteredLeads, setFilteredLeads] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [editingLeadId, setEditingLeadId] = useState(null);
    const [editedLead, setEditedLead] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [inputPage, setInputPage] = useState(1);
    const [status, setStatus] = useState('');
    const [projectGroup, setProjectGroup] = useState('');
    const [salesTeam, setSalesTeam] = useState('');
    const [planner, setPlanner] = useState('');
    const [userRole, setUserRole] = useState('');
    const [availableProjectGroups, setAvailableProjectGroups] = useState('');
    const [availableSalesTeams, setAvailableSalesTeams] = useState('');
    const [availablePlanners, setAvailablePlanners] = useState('');

    useEffect(() => {
        // 从 keycloak 中提取用户角色和可用的筛选选项
        const userGroups = keycloak.tokenParsed?.group || [];
        let role = '';
        if (userGroups.includes('/数据中心')) {
            role = 'dataCenter';
        } else if (userGroups.some(g => g.startsWith('/项目组/'))) {
            role = 'projectGroup';
            setProjectGroup(userGroups.find(g => g.startsWith('/项目组/')).split('/')[2]);
        } else if (userGroups.some(g => g.includes('/小组长'))) {
            role = 'salesManager';
            setSalesTeam(userGroups.find(g => g.includes('/小组长')).split('/')[2]);
        } else if (userGroups.some(g => g.includes('/规划师'))) {
            role = 'planner';
        }
        setUserRole(role);

        // 获取可用的筛选选项
        fetchAvailableOptions();
    }, [keycloak]);

    const fetchAvailableOptions = async () => {
        try {
            const response = await axiosInstance.get('/customer-leads/available-options');
            setAvailableProjectGroups(response.data.projectGroups);
            setAvailableSalesTeams(response.data.salesDepartments);
            setAvailablePlanners(response.data.planners);
        } catch (error) {
            console.error('获取可用选项失败', error);
        }
    };

    const fetchPendingLeads = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/pending-customer-leads', {
                params: {
                    page: page - 1,
                    searchTerm: searchTerm || undefined,
                    startDate: startDate || undefined,
                    endDate: endDate || undefined,
                    status: status || undefined,
                    projectGroup: projectGroup || undefined,
                    salesTeam: salesTeam || undefined,
                    planner: planner || undefined,
                },
            });
            setPendingLeads(response.data.content || []);
            setTotalPages(response.data.totalPages);
            setTotalItems(response.data.totalElements);
            setInputPage(page);
        } catch (error) {
            console.error('获取待补客资失败', error);
            alert('获取资料失败，请重试。');
        }
    }, [page, searchTerm, startDate, endDate, status, projectGroup, salesTeam, planner]);

    useEffect(() => {
        fetchPendingLeads();
    }, [fetchPendingLeads]);

    useEffect(() => {
        let filtered = pendingLeads;

        // if (searchTerm) {
        //     filtered = filtered.filter(lead =>
        //         lead.invalidLead.toLocaleString().includes(searchTerm) ||
        //         lead.supplementaryNotes.includes(searchTerm)
        //     );
        // }

        if (startDate) {
            filtered = filtered.filter(lead =>
                new Date(lead.date) >= new Date(startDate)
            );
        }

        if (endDate) {
            filtered = filtered.filter(lead =>
                new Date(lead.date) <= new Date(endDate)
            );
        }

        setFilteredLeads(filtered);
    }, [pendingLeads, searchTerm, startDate, endDate]);

    const handleDelete = async (leadId) => {
        if (window.confirm('确认删除该线索？')) {
            try {
                await axiosInstance.delete(`/pending-customer-leads/${leadId}`);
                fetchPendingLeads();
            } catch (error) {
                console.error('删除线索时出错', error);
            }
        }
    };

    const handleEdit = (leadId) => {
        const lead = pendingLeads.find((l) => l.id === leadId);
        setEditingLeadId(leadId);
        setEditedLead(lead);
    };

    const handleSave = async () => {
        try {
            await axiosInstance.put(`/pending-customer-leads/${editingLeadId}`, editedLead);
            fetchPendingLeads();
            setEditingLeadId(null);
        } catch (error) {
            console.error('保存修改时出错', error);
        }
    };

    const handleFieldChange = (field, value) => {
        const updatedLead = { ...editedLead, [field]: value };
        setEditedLead(updatedLead);
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const handleJumpToPage = () => {
        if (inputPage >= 1 && inputPage <= totalPages) {
            setPage(inputPage);
        } else {
            alert(`请输入有效的页码（1到${totalPages}）`);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        let startPage = Math.max(1, page - 4);
        let endPage = Math.min(totalPages, page + 5);

        if (endPage - startPage < 9) {
            startPage = Math.max(1, endPage - 9);
            endPage = Math.min(totalPages, startPage + 9);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={i === page ? 'active' : ''}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }
        return pageNumbers;
    };

    const projectGroupOrder = ['项目一', '项目二', '项目三', '项目四', '非项目渠道', '未知'];
    const salesTeamOrder = ['营销服务一部', '营销服务二部', '营销服务三部', '未分配']
    const sortedProjectGroups = Array.isArray(availableProjectGroups) ? availableProjectGroups.slice().sort((a, b) => {
        return projectGroupOrder.indexOf(a) - projectGroupOrder.indexOf(b);
    }) : [];
    const sortedSalesTeams = Array.isArray(availableSalesTeams) ? availableSalesTeams.slice().sort((a, b) => {
        return salesTeamOrder.indexOf(a) - salesTeamOrder.indexOf(b);
    }) : [];

    return (
        <div className="list-container">
            <h1>待补客资列表</h1>
            <div className="filter-container">
                <div>
                    <input
                        type="text"
                        placeholder="根据ID、客资ID、备注搜索"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <input
                        type="datetime-local"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                        type="datetime-local"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                <div>
                    {(userRole === 'dataCenter' || userRole === 'salesManager' || userRole === 'planner') && (
                        <select value={projectGroup} onChange={(e) => setProjectGroup(e.target.value)}>
                            <option value="">全部项目组</option>
                            {sortedProjectGroups && (sortedProjectGroups?.map(group => (
                                <option key={group} value={group}>{group}</option>
                            )))}
                        </select>
                    )}
                    {(userRole === 'dataCenter' || userRole === 'projectGroup') &&
                        (<select value={salesTeam} onChange={(e) => setSalesTeam(e.target.value)}>
                            <option value="">全部销售部门</option>
                            {sortedSalesTeams && (sortedSalesTeams?.map(dept => (
                                <option key={dept} value={dept}>{dept}</option>
                            )))}
                        </select>
                        )}
                    {(userRole === 'dataCenter' || userRole === 'projectGroup' || userRole === 'salesManager') &&
                        (<select value={planner} onChange={(e) => setPlanner(e.target.value)}>
                            <option value="">全部规划师</option>
                            {availablePlanners && (availablePlanners?.map(planner => (
                                <option key={planner} value={planner}>{planner ? planner : "未分配"}</option>
                            )))}
                        </select>
                        )}
                    <select onChange={(e) => setStatus(e.target.value)}>
                        <option value="">全部</option>
                        <option value="待补">待补</option>
                        <option value="已补">已补</option>
                    </select>
                </div>
            </div>
            <div className="table-scrollable">
                <table className="pending-lead-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>上传时间</th>
                            <th>项目组</th>
                            <th>销售团队</th>
                            <th>无效客资ID</th>
                            <th>已补客资ID</th>
                            <th>状态</th>
                            <th>补充说明</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredLeads.map((lead) => (
                            <tr key={lead.id}>
                                <td>{lead.id}</td>
                                <td>
                                    {new Date(lead.date).toLocaleString()}
                                </td>
                                <td>
                                    {lead.projectGroup}
                                </td>
                                <td>
                                    {lead.salesTeam}
                                </td>
                                <td>{lead.invalidLead.id}</td>
                                <td>{lead.supplementedLead ? lead.supplementedLead.id : ""}</td>
                                <td>
                                    {editingLeadId === lead.id ? (
                                        <select
                                            value={editedLead?.status || ''}
                                            onChange={(e) => handleFieldChange('status', e.target.value)}
                                            className="edit-input"
                                        >
                                            <option value="待补" disabled>待补</option>
                                            <option value="已补" disabled>已补</option>
                                            {/* Add dynamic status options as needed */}
                                        </select>
                                    ) : (
                                        lead.status
                                    )}
                                </td>
                                <td>
                                    {editingLeadId === lead.id ? (
                                        <textarea
                                            value={editedLead?.supplementaryNotes || ''}
                                            onChange={(e) => handleFieldChange('supplementaryNotes', e.target.value)}
                                            className="edit-input"
                                        />
                                    ) : (
                                        lead.supplementaryNotes ? lead.supplementaryNotes : '无'
                                    )}
                                </td>
                                <td>
                                    {editingLeadId === lead.id ? (
                                        <>
                                            <button onClick={handleSave} className="action-button">保存</button>
                                            <button onClick={() => setEditingLeadId(null)} className="action-button">取消</button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={() => handleEdit(lead.id)} className="action-button">编辑</button>
                                            <button onClick={() => handleDelete(lead.id)} className="action-button">删除</button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination-container">
                <div className="pagination">
                    <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                        上一页
                    </button>
                    {renderPageNumbers()}
                    <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
                        下一页
                    </button>
                </div>
                <div className="pagination-info">
                    <span>共 {totalItems} 条记录, {totalPages} 页</span>
                    <div className="page-jump">
                        <input
                            type="number"
                            min="1"
                            max={totalPages}
                            value={inputPage}
                            onChange={(e) => setInputPage(Number(e.target.value) || 1)}
                        />
                        <button onClick={handleJumpToPage}>跳转</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PendingCustomerLeadList;
