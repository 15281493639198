import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../axiosConfig';
import './DealList.css';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';  // Import MUI components

const DealList = ({ keycloak }) => {
  const [deals, setDeals] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [inputPage, setInputPage] = useState(1);
  const [selectedDeal, setSelectedDeal] = useState(null); // State to hold the selected deal
  const [selectedVisitor, setSelectedVisitor] = useState(null);
  const [selectedTrialResidence, setSelectedTrialResidence] = useState(null);

  const navigate = useNavigate();

  const fetchDeals = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/deals', {
        params: {
          search,
          page: page - 1,
          size: 10,
        },
      });
      if (response.data) {
        console.log(response.data.content);
        setDeals(response.data.content || []);
        setTotalPages(response.data.totalPages || 0);
        setTotalItems(response.data.totalElements || 0);
        setInputPage(page);
      }
    } catch (error) {
      console.error('Error fetching deals:', error);
      setDeals([]); // Reset deals to empty array on error
    }
  }, [search, page]);

  useEffect(() => {
    fetchDeals();
  }, [fetchDeals]);

  const handleDelete = async (id) => {
    if (window.confirm('确认删除该成交记录吗？')) {
      try {
        await axiosInstance.delete(`/deals/${id}`);
        alert('记录已删除');
        fetchDeals(); // Refresh the list
      } catch (error) {
        console.error('Error deleting record:', error);
        alert('删除失败，请重试。');
      }
    }
  };

  const handleEdit = (id) => {
    navigate(`/deal/edit/${id}`);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleJumpToPage = () => {
    if (inputPage >= 1 && inputPage <= totalPages) {
      setPage(inputPage);
    } else {
      alert(`请输入有效的页码（1到${totalPages}）`);
    }
  };

  const showDetails = async (deal) => { // 标记函数为 async
    try {
      const response = await axiosInstance.get(`/visitors/${deal.visitor}`);
      if (response.data) {
        console.log(response.data);
        setSelectedVisitor(response.data);
      }
    } catch (error) {
      console.log('Error fetching visitor', error);
    }
    try {
      const response = await axiosInstance.get(`/trial_residences`, {
        params: {
          visitorId: deal.visitor
        }
      });
      if (response.data) {
        console.log(response.data);
        setSelectedTrialResidence(response.data);
      }
    } catch (error) {
      console.log('Error fetching trialResidence', error);
    }

    setSelectedDeal(deal); // 确保在异步操作完成后再调用
  };


  const closeModal = () => {
    setSelectedDeal(null);
    setSelectedVisitor(null);
    setSelectedTrialResidence(null);
  };
  const formatKey = (key) => {
    const keyMap = {
      visitTime: "访问日期",
      name: "姓名",
      gender: "性别",
      phone: "电话",
      appointmentPhone: "预约电话",
      age: "年龄",
      occupation: "职业",
      wechat: "微信",
      residenceStatus: "居住状况",
      projectGroup: "项目组",
      visitMethod: "访问方式",
      residenceCity: "居住城市",
      residenceArea: "居住地区",
      residenceDetail: "居住详情",
      intendedBuilding: "意向楼栋",
      intendedCardType: "意向卡型",
      intendedRoomType: "意向房型",
      incomeAssets: "收入资产",
      children: "子女",
      customerConcerns: "客户关注点",
      customerResistance: "客户抗性",
      visitTimes: "访问次数",
      customerLevel: "客户等级",
      planner: "规划师",
      channelContact: "渠道联系人",
      customerStatus: "客户状态",
      followUpStatus: "跟进状态",
      followUpDetails: "跟进详情",
      remarks: "备注",
      birthDate: "出生日期",
      birthPlace: "出生地点",
      secretaryFollowUp: "秘书跟进",
      trialStartDate: "试入住开始日期",
      trialEndDate: "试入住结束日期",
      secretary: "秘书",
      roomNumber: "房间号",
      status: "状态"
    };
    return keyMap[key] || key;
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, page - 4);
    let endPage = Math.min(totalPages, page + 5);

    if (endPage - startPage < 9) {
      startPage = Math.max(1, endPage - 9);
      endPage = Math.min(totalPages, startPage + 9);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={i === page ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };
  // Helper function to determine if a field should be displayed
  const shouldDisplay = (key) => {
    return !['id', 'userId', 'department', 'visitorId'].includes(key);
  };
  return (
    <div className="deal-list">
      <h2>成交数据查询</h2>

      <div className="filters">
        <input
          type="text"
          placeholder="搜索客户姓名或房源..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>成交房源</th>
              <th>客户姓名</th>
              <th>出生日期</th>
              <th>成交日期</th>
              <th>介绍人</th>
              <th>试入住</th>
              <th>优惠政策</th>
              <th>成交金额</th>
              <th>已付款</th>
              <th>未付款</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {deals.length > 0 ? (
              deals.map((deal) => (
                <tr key={deal.id}>
                  <td>{deal.property}</td>
                  <td>{deal.customerName}</td>
                  <td>{deal.birthDate ? new Date(deal.birthDate).toLocaleDateString() : 'N/A'}</td>
                  <td>{deal.dealDate ? new Date(deal.dealDate).toLocaleDateString() : 'N/A'}</td>
                  <td>{deal.introducer}</td>
                  <td>{deal.trialResidence ? "是" : "否"}</td>
                  <td>{deal.discountPolicy}</td>
                  <td>{deal.dealAmount ? deal.dealAmount.toFixed(2) : 'N/A'}</td>
                  <td>{deal.amountPaid !== null ? deal.amountPaid.toFixed(2) : 'N/A'}</td>
                  <td>{deal.amountDue ? deal.amountDue.toFixed(2) : 'N/A'}</td>
                  <td>
                    <button onClick={() => showDetails(deal)}>详情</button>
                    <button onClick={() => handleEdit(deal.id)}>编辑</button>
                    <button onClick={() => handleDelete(deal.id)}>删除</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11">没有找到任何成交记录</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="pagination-container">
        <div className="pagination">
          <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
            上一页
          </button>
          {renderPageNumbers()}
          <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
            下一页
          </button>
        </div>
        <div className="pagination-info">
          <span>共 {totalItems} 条记录, {totalPages} 页</span>
          <div className="page-jump">
            <input
              type="number"
              min="1"
              max={totalPages}
              value={inputPage}
              onChange={(e) => setInputPage(Number(e.target.value) || 1)}
            />
            <button onClick={handleJumpToPage}>跳转</button>
          </div>
        </div>
      </div>

      {/* Modal for displaying details */}
      {selectedDeal && (
        <Dialog open onClose={closeModal}>
          <DialogTitle>详细信息</DialogTitle>
          <DialogContent>
            <p><strong>后续跟进计划:</strong> {selectedDeal.followUpPlan || '无'}</p>

            <h3>访客信息:</h3>
            {selectedVisitor && Object.keys(selectedVisitor).length > 0 ? ( // 检查访客信息是否存在且有字段
              <ul>
                {Object.entries(selectedVisitor).map(([key, value]) => {
                  if (!shouldDisplay(key)) return null; // 检查是否应显示字段
                  if (key === 'visitTime') {
                    value = new Date(value).toLocaleDateString(); // 格式化访问时间为日期
                  }
                  return (
                    <li key={key}>
                      <strong>{formatKey(key)}:</strong> {value || '无'}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p>无访客信息</p> // 访客信息为空时的提示
            )}

            <h3>试入住信息:</h3>
            {selectedTrialResidence && Object.keys(selectedTrialResidence).length > 0 ? ( // 检查试入住信息是否存在且有字段
              <ul>
                {Object.entries(selectedTrialResidence).map(([key, value]) => {
                  if (!shouldDisplay(key)) return null; // 检查是否应显示字段
                  return (
                    <li key={key}>
                      <strong>{formatKey(key)}:</strong> {value || '无'}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p>无试入住信息</p> // 试入住信息为空时的提示
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              关闭
            </Button>
          </DialogActions>
        </Dialog>
      )}

    </div>
  );
};

export default DealList;
