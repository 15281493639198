import React, { useState, useEffect, useCallback } from "react";
import axiosInstance from "../../axiosConfig";
import "./VisitorList.css";

import {
  genderOptions,
  incomeAssetsOptions,
  residenceStatusOptions,
  followUpStatusOptions,
  customerLevelOptions,
  buildingOptions,
  customerResistanceOptions,
  getVisitMethods,
  getResidenceAreas,
  getCardTypes,
  getRoomTypes,
  visitMethodConfig,
  residenceAreaConfig,
  wxStateOptions,
} from "../../config/fieldConfig";

const VisitorList = ({ keycloak }) => {
  const [visitors, setVisitors] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedVisitor, setSelectedVisitor] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedVisitor, setEditedVisitor] = useState(null);
  const [inputPage, setInputPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [projectGroup, setProjectGroup] = useState('');
  const [salesTeam, setSalesTeam] = useState('');
  const [planner, setPlanner] = useState('');
  const [visitTimes, setVisitTimes] = useState('');
  const [customerLevel, setCustomerLevel] = useState('');
  const [userRole, setUserRole] = useState('');
  const [availableProjectGroups, setAvailableProjectGroups] = useState('');
  const [availableSalesTeams, setAvailableSalesTeams] = useState('');
  const [availablePlanners, setAvailablePlanners] = useState('');
  const [availableVisitTimes, setAvailableVisitTimes] = useState('');
  const [availableCustomerLevel, setAvailableCustomerLevel] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // 从 keycloak 中提取用户角色和可用的筛选选项
    const userGroups = keycloak.tokenParsed?.group || [];
    let role = '';
    if (userGroups.includes('/数据中心')) {
      role = 'dataCenter';
    } else if (userGroups.some(g => g.startsWith('/项目组/'))) {
      role = 'projectGroup';
      // setProjectGroup(userGroups.find(g => g.startsWith('/项目组/')).split('/')[2]);
    } else if (userGroups.some(g => g.includes('/小组长'))) {
      role = 'salesManager';
      // setSalesTeam(userGroups.find(g => g.includes('/小组长')).split('/')[2]);
    } else if (userGroups.some(g => g.includes('/规划师'))) {
      role = 'planner';
    }
    setUserRole(role);

    // 获取可用的筛选选项
    fetchAvailableOptions();
  }, [keycloak]);

  const fetchAvailableOptions = async () => {
    try {
      const response = await axiosInstance.get('/visitors/available-options');
      setAvailableProjectGroups(response.data.projectGroups);
      setAvailableSalesTeams(response.data.salesDepartments);
      setAvailablePlanners(response.data.planners);
      setAvailableVisitTimes(response.data.visitTimes);
      setAvailableCustomerLevel(response.data.customerLevel);
    } catch (error) {
      console.error('获取可用选项失败', error);
    }
  };
  // 字段翻译映射
  const visitorFieldTranslations = {
    id: "访客编号",
    visitTime: "访问时间",
    name: "姓名",
    phone: "电话",
    appointmentPhone: "预约电话",
    gender: "性别",
    age: "年龄",
    occupation: "职业",
    wechat: "微信状态",
    residenceStatus: "居住状态",
    projectGroup: "项目组",
    visitMethod: "访问渠道",
    residenceCity: "居住城市",
    residenceArea: "居住区域",
    residenceDetail: "居住详细地址",
    intendedBuilding: "意向楼栋",
    intendedCardType: "意向卡种",
    intendedRoomType: "意向房型",
    incomeAssets: "资产状况",
    children: "子女情况",
    customerConcerns: "客户关注点",
    customerResistance: "客户抗性",
    visitTimes: "访问次数",
    customerLevel: "客户等级",
    planner: "规划师",
    customerStatus: "客户状态",
    followUpStatus: "跟进状态",
    followUpDetails: "跟进详情",
    remarks: "备注",
    department: "部门",
    uploader: "上传者",
    uploadTime: "上传时间",
    lastUpdateTime: "最后一次更新时间"
  }

  const customerLeadFieldTranslations = {
    id: "客资编号",
    dateTime: "创建时间",
    name: "姓名",
    phone: "电话",
    referrer: "介绍人",
    transferDemand: "接送需求",
    intendedVisitTime: "意向访问时间",
    likelyToCloseIn6Months: "6个月内有望成交",
    hasPaymentAbilityForSeniorCare: "有养老消费买单能力",
    notLivingWithChildren: "不与子女生活",
    age: "年龄",
    customerLevel: "客户等级",
    projectGroup: "项目组",
    validityOfLeads: "客资有效性",
    uploader: "上传者",
    uploadTime: "上传时间",
    leadDistributionPlanner: "分配规划师",
    leadDistributionDepartment: "分配部门"
  };

  const customerTableHeaders = {
    id: "客户编号",
    identityCard: "身份证号",
    name: "姓名",
    phone: "电话",
    channel: "渠道",
    uploader: "上传人",
    uploadTime: "上传时间",
  };

  const fetchVisitors = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/visitors", {
        params: {
          page: page - 1,
          searchTerm: searchTerm || undefined,
          startDate: startDate || undefined,
          endDate: endDate || undefined,
          projectGroup: projectGroup || undefined,
          salesTeam: salesTeam || undefined,
          planner: planner || undefined,
          visitTimes: visitTimes || undefined,
          customerLevel: customerLevel || undefined
        },
      });
      setVisitors(response.data.content);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalElements);
    } catch (error) {
      console.error("获取访客数据失败", error);
    }
  }, [page, searchTerm, startDate, endDate, projectGroup, salesTeam, planner, visitTimes, customerLevel]);

  const handleExportVisitors = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get("/visitors/export", {
        params: {
          searchTerm: searchTerm || undefined,
          startDate: startDate || undefined,
          endDate: endDate || undefined,
          projectGroup: projectGroup || undefined,
          salesTeam: salesTeam || undefined,
          planner: planner || undefined,
          visitTimes: visitTimes || undefined,
          customerLevel: customerLevel || undefined
        },
        responseType: 'arraybuffer'
      });
      // 直接使用响应数据创建 Blob 对象
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      // 创建一个临时URL
      const url = window.URL.createObjectURL(blob);

      // 创建一个临时的a标签来触发下载
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'visitors.xlsx';

      // 添加到DOM，触发点击，然后移除
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("访客数据导出失败!", error);
      setError('下载失败，请稍后重试。');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchVisitors();
  }, [fetchVisitors]);

  const handleViewDetails = (visitor) => {
    setSelectedVisitor(visitor);
    setEditedVisitor({ ...visitor });
    setIsEditing(false);
  };

  const handleFieldChange = (field, value) => {
    setEditedVisitor({ ...editedVisitor, [field]: value });
  };

  const handleProjectGroupChange = (projectGroup) => {
    const methods = getVisitMethods(projectGroup);
    setEditedVisitor((prevState) => ({
      ...prevState,
      projectGroup,
      visitMethod: methods[0],
    }));
  };

  const handleBuildingChange = (building) => {
    const cards = getCardTypes(building);
    setEditedVisitor((prevState) => ({
      ...prevState,
      intendedBuilding: building,
      intendedCardType: cards[0],
      intendedRoomType: getRoomTypes(cards[0])[0],
    }));
  };

  const handleCardTypeChange = (cardType) => {
    const rooms = getRoomTypes(cardType);
    setEditedVisitor((prevState) => ({
      ...prevState,
      intendedCardType: cardType,
      intendedRoomType: rooms[0],
    }));
  };

  const handleCityChange = (city) => {
    const areas = getResidenceAreas(city);
    setEditedVisitor((prevState) => ({
      ...prevState,
      residenceCity: city,
      residenceArea: areas[0],
    }));
  };

  const handleSave = async () => {
    try {
      await axiosInstance.put(`/visitors/${editedVisitor.id}`, editedVisitor);
      // alert("保存成功！");
      setSelectedVisitor(editedVisitor);
      setIsEditing(false);
      fetchVisitors();
    } catch (error) {
      console.error("保存失败", error);
      alert("保存失败，请稍后再试！");
    }
  };

  const handleDelete = async (visitorId) => {
    if (window.confirm("是否确认删除此访客记录？")) {
      try {
        await axiosInstance.delete(`/visitors/${visitorId}`);
        // alert("删除成功！");
        fetchVisitors();
      } catch (error) {
        console.error("删除失败", error);
        alert("删除失败，请稍后再试！");
      }
    }
  };

  const handleCancel = () => {
    setEditedVisitor(selectedVisitor);
    setIsEditing(false);
  };

  const formatBoolean = (value) => (value === true ? "是" : value === false ? "否" : "无");

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, page - 4);
    let endPage = Math.min(totalPages, page + 5);

    if (endPage - startPage < 9) {
      startPage = Math.max(1, endPage - 9);
      endPage = Math.min(totalPages, startPage + 9);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={i === page ? "active" : ""}
          onClick={() => setPage(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const projectGroupOrder = ['项目一', '项目二', '项目三', '项目四', '非项目渠道', '未知'];
  const customerLevelOrder = ['A', 'B', 'C'];

  const sortedVisitTimes = Array.isArray(availableVisitTimes) ? availableVisitTimes.slice().sort((a, b) => a - b) : [];
  const sortedProjectGroups = Array.isArray(availableProjectGroups) ? availableProjectGroups.slice().sort((a, b) => {
    return projectGroupOrder.indexOf(a) - projectGroupOrder.indexOf(b);
  }) : [];
  const sortedCustomerLevel = Array.isArray(availableCustomerLevel) ? availableCustomerLevel.slice().sort((a, b) => {
    return customerLevelOrder.indexOf(a) - customerLevelOrder.indexOf(b);
  }) : [];

  return (
    <div className="visitor-list-container">
      <h1>访客查询</h1>
      <div className="table-scrollable">
        <div className="filter-container">
          <div>
            <input
              type="text"
              placeholder="根据客资ID、姓名、电话搜索"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <input
              type="datetime-local"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <input
              type="datetime-local"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div>
            <select value={visitTimes} onChange={(e) => setVisitTimes(e.target.value)}>
              <option value="">全部访问次数</option>
              {sortedVisitTimes && (sortedVisitTimes?.map(visitTimes => (
                <option key={visitTimes} value={visitTimes}>{visitTimes}</option>
              )))}
            </select>
            <select value={customerLevel} onChange={(e) => setCustomerLevel(e.target.value)}>
              <option value="">全部客户等级</option>
              {sortedCustomerLevel && (sortedCustomerLevel?.map(customerLevel => (
                <option key={customerLevel} value={customerLevel}>{customerLevel}</option>
              )))}
            </select>

            {(userRole === 'dataCenter' || userRole === 'salesManager' || userRole === 'planner') && (
              <select value={projectGroup} onChange={(e) => setProjectGroup(e.target.value)}>
                <option value="">全部项目组</option>
                {sortedProjectGroups && (sortedProjectGroups?.map(group => (
                  <option key={group} value={group}>{group}</option>
                )))}
              </select>
            )}
            {(userRole === 'dataCenter' || userRole === 'projectGroup') &&
              (<select value={salesTeam} onChange={(e) => setSalesTeam(e.target.value)}>
                <option value="">全部销售部门</option>
                {availableSalesTeams && (availableSalesTeams?.map(dept => (
                  <option key={dept} value={dept}>{dept}</option>
                )))}
              </select>
              )}

            {(userRole === 'dataCenter' || userRole === 'projectGroup' || userRole === 'salesManager') &&
              (<select value={planner} onChange={(e) => setPlanner(e.target.value)}>
                <option value="">全部规划师</option>
                {availablePlanners && (availablePlanners?.map(planner => (
                  <option key={planner} value={planner}>{planner}</option>
                )))}
              </select>
              )}
          </div>
        </div>

        <table className="visitor-table">
          <thead>
            <tr>
              <th>访问时间</th>
              <th>姓名</th>
              <th>电话</th>
              <th>预约电话</th>
              <th>访问次数</th>
              <th>项目组</th>
              <th>客户等级</th>
              <th>规划师</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {visitors.map((visitor) => (
              <tr key={visitor.id}>
                <td>{new Date(visitor.visitTime).toLocaleString()}</td>
                <td class='truncate'>{visitor.name}</td>
                <td class='truncate'>{visitor.phone}</td>
                <td class='truncate'>{visitor.appointmentPhone}</td>
                <td>{visitor.visitTimes}</td>
                <td>{visitor.projectGroup}</td>
                <td>{visitor.customerLevel || "无"}</td>
                <td>{visitor.planner}</td>
                <td>
                  <button onClick={() => handleViewDetails(visitor)}>查看详细</button>
                  <button onClick={() => handleDelete(visitor.id)}>删除</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <div className="pagination">
          <button onClick={() => setPage(page - 1)} disabled={page === 1}>
            上一页
          </button>
          {renderPageNumbers()}
          <button onClick={() => setPage(page + 1)} disabled={page === totalPages}>
            下一页
          </button>
        </div>
        <div className="pagination-info">
          <span>共 {totalItems} 条记录, {totalPages} 页</span>
          <div className="page-jump">
            <input
              type="number"
              min="1"
              max={totalPages}
              value={inputPage}
              onChange={(e) => setInputPage(Number(e.target.value) || 1)}
            />
            <button onClick={() => setPage(inputPage)}>跳转</button>
          </div>
        </div>
        {(<div className="export">
          <button onClick={handleExportVisitors} disabled={loading}>
            {loading ? '下载中...' : '导出访客数据'}
          </button>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>)}
      </div>

      {selectedVisitor && (
        <div className="visitor-details-modal">
          <div className="visitor-details-content">
            <button className="close-button" onClick={() => setSelectedVisitor(null)}>关闭</button>
            <h2>访客详细信息</h2>
            {isEditing ? (
              <VisitorEditModal
                editedVisitor={editedVisitor}
                handleFieldChange={handleFieldChange}
                handleProjectGroupChange={handleProjectGroupChange}
                handleBuildingChange={handleBuildingChange}
                handleCardTypeChange={handleCardTypeChange}
                handleCityChange={handleCityChange}
                handleSave={handleSave}
                handleCancel={handleCancel}
              />
            ) : (
              <div>
                <h3>访客信息</h3>
                <ul>
                  {Object.entries(visitorFieldTranslations).map(([field, label]) => (
                    <li key={field}>
                      <strong>{label}:</strong> <span className="value-area">{selectedVisitor[field] || "无"}</span>
                    </li>
                  ))}
                </ul>
                <button className="edit-button" onClick={() => setIsEditing(true)}>编辑</button>
              </div>
            )}
            <h3>关联客资信息</h3>
            {selectedVisitor.customerLead ? (
              <>
                <ul>
                  {Object.entries(selectedVisitor.customerLead).map(([key, value]) => (
                    key !== "customers" && (
                      <li key={key}>
                        <strong>{customerLeadFieldTranslations[key] || key}:</strong>{" "}
                        {typeof value === "boolean" ? formatBoolean(value) : value || "无"}
                      </li>
                    )
                  ))}
                </ul>
                <h3>关联客户</h3>
                <table className="customer-table">
                  <thead>
                    <tr>
                      {Object.keys(customerTableHeaders).map((header) => (
                        <th key={header}>{customerTableHeaders[header]}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {selectedVisitor.customerLead.customers.map((customer) => (
                      <tr key={customer.id}>
                        {Object.keys(customerTableHeaders).map((field) => (
                          <td key={field}>
                            {field === "uploadTime"
                              ? new Date(customer[field]).toLocaleString()
                              : customer[field] || "无"}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>无关联客资信息</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const VisitorEditModal = ({ editedVisitor, handleFieldChange, handleProjectGroupChange, handleBuildingChange, handleCardTypeChange, handleCityChange, handleSave, handleCancel }) => {
  return (
    <div>
      <h3>编辑访客信息</h3>
      <ul>
        <li>
          <strong>访问时间:</strong>
          <input
            type="datetime-local"
            value={editedVisitor.visitTime ? new Date(editedVisitor.visitTime).toISOString().slice(0, 16) : ""}
            onChange={(e) => handleFieldChange("visitTime", e.target.value)}
          />
        </li>
        <li>
          <strong>姓名:</strong>
          <input
            type="text"
            value={editedVisitor.name || ""}
            onChange={(e) => handleFieldChange("name", e.target.value)}
          />
        </li>
        <li>
          <strong>电话:</strong>
          <input
            type="tel"
            value={editedVisitor.phone || ""}
            onChange={(e) => handleFieldChange("phone", e.target.value)}
          />
        </li>
        <li>
          <strong>预约电话:</strong>
          <input
            type="tel"
            value={editedVisitor.appointmentPhone || ""}
            onChange={(e) => handleFieldChange("appointmentPhone", e.target.value)}
          />
        </li>
        <li>
          <strong>性别:</strong>
          <select
            value={editedVisitor.gender || ""}
            onChange={(e) => handleFieldChange("gender", e.target.value)}
          >
            <option value="" disabled>请选择</option>
            {genderOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </li>
        <li>
          <strong>年龄:</strong>
          <input
            type="number"
            value={editedVisitor.age || ""}
            onChange={(e) => handleFieldChange("age", e.target.value)}
          />
        </li>
        <li>
          <strong>职业:</strong>
          <input
            type="text"
            value={editedVisitor.occupation || ""}
            onChange={(e) => handleFieldChange("occupation", e.target.value)}
          />
        </li>
        <li>
          <strong>微信状态:</strong>
          <select
            value={editedVisitor.wechat || ""}
            onChange={(e) => handleFieldChange("wechat", e.target.value)}
          >
            <option value="" disabled>请选择</option>
            {wxStateOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </li>
        <li>
          <strong>居住状态:</strong>
          <select
            value={editedVisitor.residenceStatus || ""}
            onChange={(e) => handleFieldChange("residenceStatus", e.target.value)}
          >
            <option value="" disabled>请选择</option>
            {residenceStatusOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </li>
        <li>
          <strong>项目组:</strong>
          <select
            value={editedVisitor.projectGroup || ""}
            onChange={(e) => handleProjectGroupChange(e.target.value)}
          >
            <option value="" disabled>请选择</option>
            {Object.keys(visitMethodConfig).map((group, index) => (
              <option key={index} value={group}>{group}</option>
            ))}
          </select>
        </li>
        <li>
          <strong>访问渠道:</strong>
          <select
            value={editedVisitor.visitMethod || ""}
            onChange={(e) => handleFieldChange("visitMethod", e.target.value)}
          >
            <option value="" disabled>请选择</option>
            {getVisitMethods(editedVisitor.projectGroup).map((method, index) => (
              <option key={index} value={method}>{method}</option>
            ))}
          </select>
        </li>
        <li>
          <strong>居住城市:</strong>
          <select
            value={editedVisitor.residenceCity || ""}
            onChange={(e) => handleCityChange(e.target.value)}
          >
            <option value="" disabled>请选择</option>
            {Object.keys(residenceAreaConfig).map((city, index) => (
              <option key={index} value={city}>{city}</option>
            ))}
          </select>
        </li>
        <li>
          <strong>居住区域:</strong>
          <select
            value={editedVisitor.residenceArea || ""}
            onChange={(e) => handleFieldChange("residenceArea", e.target.value)}
          >
            <option value="" disabled>请选择</option>
            {getResidenceAreas(editedVisitor.residenceCity).map((area, index) => (
              <option key={index} value={area}>{area}</option>
            ))}
          </select>
        </li>
        <li>
          <strong>居住详细地址:</strong>
          <input
            type="text"
            value={editedVisitor.residenceDetail || ""}
            onChange={(e) => handleFieldChange("residenceDetail", e.target.value)}
          />
        </li>
        <li>
          <strong>意向楼栋:</strong>
          <select
            value={editedVisitor.intendedBuilding || ""}
            onChange={(e) => handleBuildingChange(e.target.value)}
          >
            <option value="" disabled>请选择</option>
            {buildingOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </li>
        <li>
          <strong>意向卡种:</strong>
          <select
            value={editedVisitor.intendedCardType || ""}
            onChange={(e) => handleCardTypeChange(e.target.value)}
          >
            <option value="" disabled>请选择</option>
            {getCardTypes(editedVisitor.intendedBuilding).map((card, index) => (
              <option key={index} value={card}>{card}</option>
            ))}
          </select>
        </li>
        <li>
          <strong>意向房型:</strong>
          <select
            value={editedVisitor.intendedRoomType || ""}
            onChange={(e) => handleFieldChange("intendedRoomType", e.target.value)}
          >
            <option value="" disabled>请选择</option>
            {getRoomTypes(editedVisitor.intendedCardType).map((room, index) => (
              <option key={index} value={room}>{room}</option>
            ))}
          </select>
        </li>
        <li>
          <strong>资产状况:</strong>
          <select
            value={editedVisitor.incomeAssets || ""}
            onChange={(e) => handleFieldChange("incomeAssets", e.target.value)}
          >
            <option value="" disabled>请选择</option>
            {incomeAssetsOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </li>
        <li>
          <strong>子女情况:</strong>
          <input
            type="text"
            value={editedVisitor.children || ""}
            onChange={(e) => handleFieldChange("children", e.target.value)}
          />
        </li>
        <li>
          <strong>客户关注点:</strong>
          <input
            type="text"
            value={editedVisitor.customerConcerns || ""}
            onChange={(e) => handleFieldChange("customerConcerns", e.target.value)}
          />
        </li>
        <li>
          <strong>客户抗性:</strong>
          <select
            value={editedVisitor.customerResistance || ""}
            onChange={(e) => handleFieldChange("customerResistance", e.target.value)}
          >
            <option value="" disabled>请选择</option>
            {customerResistanceOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </li>
        <li>
          <strong>访问次数:</strong>
          <input
            type="number"
            value={editedVisitor.visitTimes || ""}
            onChange={(e) => handleFieldChange("visitTimes", e.target.value)}
          />
        </li>
        <li>
          <strong>客户等级:</strong>
          <select
            value={editedVisitor.customerLevel || ""}
            onChange={(e) => handleFieldChange("customerLevel", e.target.value)}
          >
            <option value="" disabled>请选择</option>
            {customerLevelOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </li>
        <li>
          <strong>规划师:</strong>
          <input
            type="text"
            value={editedVisitor.planner || ""}
            onChange={(e) => handleFieldChange("planner", e.target.value)}
          />
        </li>
        <li>
          <strong>客户状态:</strong>
          <textarea
            value={editedVisitor.customerStatus || ""}
            onChange={(e) => handleFieldChange("customerStatus", e.target.value)}
          />
        </li>
        <li>
          <strong>跟进状态:</strong>
          <select
            value={editedVisitor.followUpStatus || ""}
            onChange={(e) => handleFieldChange("followUpStatus", e.target.value)}
          >
            <option value="" disabled>请选择</option>
            {followUpStatusOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </li>
        <li>
          <strong>跟进详情:</strong>
          <textarea
            value={editedVisitor.followUpDetails || ""}
            onChange={(e) => handleFieldChange("followUpDetails", e.target.value)}
          />
        </li>
        <li>
          <strong>备注:</strong>
          <textarea
            value={editedVisitor.remarks || ""}
            onChange={(e) => handleFieldChange("remarks", e.target.value)}
          />
        </li>
      </ul>
      <div className="modal-actions">
        <button onClick={handleSave}>保存</button>
        <button onClick={handleCancel}>取消</button>
      </div>
    </div>
  );
};



export default VisitorList;