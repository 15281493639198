import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DealEntryForm from './components/Deal/DealEntryForm';
import DealList from './components/Deal/DealList';
import DealEditForm from './components/Deal/DealEditForm';
import CustomerLeadsForm from './components/CustomerResource/CustomerLeadsForm';
import CustomerLeadsList from './components/CustomerResource/CustomerLeadsList';
import LeadDistributionForm from './components/LeadDistribut/LeadDistributionForm';
import LeadDistributionList from './components/LeadDistribut/LeadDistributionList';
import PendingCustomerLeadForm from './components/PendingCustomerLead/PendingCustomerLeadForm';
import PendingCustomerLeadList from './components/PendingCustomerLead/PendingCustomerLeadList';
import CustomerList from './components/Customers/CustomerList';
import CustomerForm from './components/Customers/CustomerForm';
import VisitorForm from './components/Visitor/VisitorForm';
import VisitorList from './components/Visitor/VisitorList';

import Sidebar from './Sidebar';
import Navbar from './Navbar';
import './App.css';

const App = ({ keycloak }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      keycloak.updateToken(70).then(refreshed => {
        if (refreshed) {
          console.log('Token refreshed', keycloak.token);
        } else {
          console.warn('Token not refreshed, valid for', Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000), 'seconds');
        }
      }).catch(() => {
        console.error('Failed to refresh token');
      });
    }, 60000);

    return () => clearInterval(refreshInterval);
  }, [keycloak]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Router>
      <div className={`app-container ${sidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <Navbar keycloak={keycloak} toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen} />
        {sidebarOpen && <Sidebar keycloak={keycloak} />}
        <div className="content">
          <Routes>
            <Route path="/" element={<CustomerList keycloak={keycloak} />} /> {/* 新增根路径 */}
            <Route path="/deal" element={<DealList keycloak={keycloak} />} />
            <Route path="/add-deal" element={<DealEntryForm keycloak={keycloak} />} />
            <Route path="/deal/edit/:id" element={<DealEditForm keycloak={keycloak} />} />
            <Route path="/CustomerLeadsForm" element={<CustomerLeadsForm keycloak={keycloak} />} />
            <Route path="/CustomerLeadsList" element={<CustomerLeadsList keycloak={keycloak} />} />
            <Route path='/LeadDistributionForm' element={<LeadDistributionForm keycloak={keycloak} />} />
            <Route path='/LeadDistributionList' element={<LeadDistributionList keycloak={keycloak} />} />
            <Route path='/PendingCustomerLeadForm' element={<PendingCustomerLeadForm keycloak={keycloak} />} />
            <Route path='/PendingCustomerLeadList' element={<PendingCustomerLeadList keycloak={keycloak} />} />
            <Route path='/CustomerForm' element={<CustomerForm keycloak={keycloak} />} />
            <Route path='/CustomerList' element={<CustomerList keycloak={keycloak} routeType="normal" />} />
            <Route path='/CustomerListLeadUpload' element={<CustomerList keycloak={keycloak} routeType="leadUpload" />} />
            <Route path='/VisitorForm' element={<VisitorForm keycloak={keycloak} />} />
            <Route path='/VisitorList' element={<VisitorList keycloak={keycloak} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
