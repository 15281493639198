import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import axiosInstance from "../../axiosConfig";
import './VisitorForm.css';

import {
  genderOptions,
  incomeAssetsOptions,
  residenceStatusOptions,
  followUpStatusOptions,
  customerLevelOptions,
  buildingOptions,
  customerResistanceOptions,
  getVisitMethods,
  getResidenceAreas,
  getCardTypes,
  getRoomTypes,
  visitMethodConfig,
  residenceAreaConfig,
  wxStateOptions
} from "../../config/fieldConfig";

const VisitorForm = ({ keycloak }) => {
  // State hooks to manage form data dynamically
  const [visitMethods, setVisitMethods] = useState([]);
  const [residenceAreas, setResidenceAreas] = useState([]);
  const [cardTypes, setCardTypes] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // Set default values for visitMethods, residenceAreas, cardTypes, and roomTypes based on initial values
    setVisitMethods(getVisitMethods(Object.keys(visitMethodConfig)[0]));
    setResidenceAreas(getResidenceAreas("上海市"));
    setCardTypes(getCardTypes(buildingOptions[0]));
    setRoomTypes(getRoomTypes(getCardTypes(buildingOptions[0])[0]));
  }, []);

  // Handle project group change and update visit methods accordingly
  const handleProjectGroupChange = (projectGroup, setFieldValue) => {
    const methods = getVisitMethods(projectGroup);
    setVisitMethods(methods);
    setFieldValue("projectGroup", projectGroup);
    setFieldValue("visitMethod", methods[0]);
  };

  // Handle building change and update card types accordingly
  const handleBuildingChange = (building, setFieldValue) => {
    const cards = getCardTypes(building);
    setCardTypes(cards);
    setRoomTypes([]);
    setFieldValue("intendedBuilding", building);
    handleCardTypeChange(cards[0], setFieldValue);
  };

  // Handle card type change and update room types accordingly
  const handleCardTypeChange = (cardType, setFieldValue) => {
    const rooms = getRoomTypes(cardType);
    setRoomTypes(rooms);
    setFieldValue("intendedCardType", cardType);
    setFieldValue("intendedRoomType", rooms[0]);
  };

  // Handle city change and update residence areas accordingly
  const handleCityChange = (city, setFieldValue) => {
    const areas = getResidenceAreas(city);
    setResidenceAreas(areas);
    setFieldValue("residenceCity", city);
    setFieldValue("residenceArea", areas[0]);
  };

  // Fetch customer leads based on search query
  const fetchCustomerLeads = async (query) => {
    try {
      const response = await axiosInstance.get("/customer-leads", {
        params: { search: query },
      });
      const options = response.data.content.map((lead) => ({
        value: lead.id,
        label: `ID: ${lead.id} - ${lead.name} - ${lead.phone}`,
        lead,
      }));
      setCustomerOptions(options);
    } catch (error) {
      console.error("获取客资失败：", error);
    }
  };

  // Handle change in the customer search input
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    fetchCustomerLeads(event.target.value);
  };

  // Handle customer selection from the dropdown and auto-fill form fields
  const handleCustomerSelection = (option, setFieldValue) => {
    setSelectedCustomer(option?.lead || null);
    if (option?.lead) {
      const { name, phone, age, intendedVisitTime, customerLevel, projectGroup, department, notLivingWithChildren } = option.lead;
      setFieldValue("name", name || "");
      setFieldValue("appointmentPhone", phone || "");
      setFieldValue("age", age || "");
      const formattedVisitTime = intendedVisitTime ? new Date(intendedVisitTime).toISOString().slice(0, 16) : "";
      setFieldValue("visitTime", formattedVisitTime);
      setFieldValue("customerLevel", customerLevel || "");
      setFieldValue("department", department || "");
      handleProjectGroupChange(projectGroup, setFieldValue);
      if (notLivingWithChildren) {
        setFieldValue("residenceStatus", "独居");
      }
    }
  };

  return (
    <div className="visitor-form-container">
      <h1>访客数据录入</h1>

      {/* Search and filter customer leads */}
      <div className="form-group">
        <label htmlFor="customer_search">搜索客资：</label>
        <input
          id="customer_search"
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="请输入ID、姓名或电话查询"
          className="search-input"
        />
      </div>

      <Formik
        initialValues={{
          visitTime: "",
          name: "",
          gender: genderOptions[0],
          phone: "",
          appointmentPhone: "",
          age: "",
          occupation: "",
          wechat: wxStateOptions[0],
          residenceStatus: "",
          projectGroup: Object.keys(visitMethodConfig)[0],
          visitMethod: getVisitMethods(Object.keys(visitMethodConfig)[0])[0],
          residenceCity: "上海市",
          residenceArea: getResidenceAreas("上海市")[0],
          residenceDetail: "",
          intendedBuilding: buildingOptions[0],
          intendedCardType: getCardTypes(buildingOptions[0])[0],
          intendedRoomType: getRoomTypes(getCardTypes(buildingOptions[0])[0])[0],
          incomeAssets: incomeAssetsOptions[0],
          children: "",
          customerConcerns: "",
          customerResistance: "",
          visitTimes: 1,
          customerLevel: "",
          planner: keycloak.tokenParsed?.family_name + keycloak.tokenParsed?.given_name,
          customerStatus: "",
          followUpStatus: followUpStatusOptions[0],
          followUpDetails: "",
          remarks: "",
          customerLeadId: ""
        }}
        validationSchema={Yup.object({
          visitTime: Yup.date().required("访问日期是必填项"),
          name: Yup.string().required("姓名是必填项"),
          gender: Yup.string().required("性别是必选项"),
          phone: Yup.string().required("电话是必填项"),
          appointmentPhone: Yup.string().required("预约电话是必填项"),
          age: Yup.number().required("年龄是必填项").min(0, "年龄不能为负数"),
          customerResistance: Yup.string().required("客户抗性是必填项"),
          visitTimes: Yup.number().required("访问次数是必填项").min(0, "访问次数至少为0"),
          followUpStatus: Yup.string().required("跟进状态是必选项"),
          residenceStatus: Yup.string().required("居住状态是必选项"),
          customerLevel: Yup.string().required("客户等级是必选项"),
          customerLeadId: Yup.string().required("客资是必选项")
        })}

        onSubmit={async (values, { setSubmitting }) => {
          try {
            const response = await axiosInstance.post(`/visitors`, values);
            console.log('数据提交成功', response.data);
            // alert('数据提交成功！');
          } catch (error) {
            console.error('提交数据时出错', error);
            alert('提交失败，请重试。');
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            {/* Select customer lead */}
            <div className="form-group">
              <label htmlFor="customerLead">选择客资：</label>
              <Select
                options={customerOptions}
                onChange={(option) => {
                  handleCustomerSelection(option, setFieldValue);
                  setFieldValue("customerLeadId", option?.value || ""); // 更新表单中 customerLeadId 字段
                }}
                placeholder="请选择客资"
                className="select-field"
              />
              <ErrorMessage name="customerLeadId" component="div" className="error-message" />
              {selectedCustomer && (
                <p className="selected-customer-info">
                  已选择客资：{selectedCustomer.name}（{selectedCustomer.phone}）
                </p>
              )}
            </div>

            {/* Visit time field */}
            <div className="form-group">
              <label htmlFor="visitTime">访问时间</label>
              <Field type="datetime-local" name="visitTime" className="form-field" />
              <ErrorMessage name="visitTime" component="div" className="error-message" />
            </div>

            {/* Customer name field */}
            <div className="form-group">
              <label htmlFor="name">姓名</label>
              <Field type="text" name="name" className="form-field" />
              <ErrorMessage name="name" component="div" className="error-message" />
            </div>

            {/* Gender field */}
            <div className="form-group">
              <label htmlFor="gender">性别</label>
              <Field as="select" name="gender" className="form-field">
                <option value="" disabled>请选择</option>
                {genderOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="gender" component="div" className="error-message" />
            </div>

            {/* Customer phone field */}
            <div className="form-group">
              <label htmlFor="phone">电话</label>
              <Field type="text" name="phone" className="form-field" />
              <ErrorMessage name="phone" component="div" className="error-message" />
            </div>

            {/* Appointment phone field */}
            <div className="form-group">
              <label htmlFor="appointmentPhone">预约电话</label>
              <Field type="text" name="appointmentPhone" className="form-field" />
              <ErrorMessage name="appointmentPhone" component="div" className="error-message" />
            </div>

            {/* Customer age field */}
            <div className="form-group">
              <label htmlFor="age">年龄</label>
              <Field type="number" name="age" className="form-field" />
              <ErrorMessage name="age" component="div" className="error-message" />
            </div>

            {/* Occupation field */}
            <div className="form-group">
              <label htmlFor="occupation">职业</label>
              <Field type="text" name="occupation" className="form-field" />
              <ErrorMessage name="occupation" component="div" className="error-message" />
            </div>

            {/* WeChat field */}
            <div className="form-group">
              <label htmlFor="wechat">微信</label>
              <Field as="select" name="wechat" className="form-field">
                {wxStateOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="wechat" component="div" className="error-message" />
            </div>

            {/* Residence status field */}
            <div className="form-group">
              <label htmlFor="residenceStatus">居住状态</label>
              <Field as="select" name="residenceStatus" className="form-field">
                <option value="">请选择</option>
                {residenceStatusOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="residenceStatus" component="div" className="error-message" />
            </div>

            {/* Project group field */}
            <div className="form-group">
              <label htmlFor="projectGroup">项目组</label>
              <Field
                as="select"
                name="projectGroup"
                className="form-field"
                onChange={(e) => handleProjectGroupChange(e.target.value, setFieldValue)}
              >
                <option value="" disabled>请选择</option>
                {Object.keys(visitMethodConfig)?.map((group, index) => (
                  <option key={index} value={group} disabled>
                    {group}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="projectGroup" component="div" className="error-message" />
            </div>

            {/* Visit method field */}
            <div className="form-group">
              <label htmlFor="visitMethod">渠道</label>
              <Field as="select" name="visitMethod" className="form-field">
                <option value="">请选择</option>
                {visitMethods.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="visitMethod" component="div" className="error-message" />
            </div>

            {/* Residence city field */}
            <div className="form-group">
              <label htmlFor="residenceCity">居住城市</label>
              <Field
                as="select"
                name="residenceCity"
                className="form-field"
                onChange={(e) => handleCityChange(e.target.value, setFieldValue)}
              >
                <option value="" disabled>请选择</option>
                {Object.keys(residenceAreaConfig)?.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="residenceCity" component="div" className="error-message" />
            </div>

            {/* Residence area field */}
            <div className="form-group">
              <label htmlFor="residenceArea">居住区域</label>
              <Field as="select" name="residenceArea" className="form-field">
                <option value="">请选择</option>
                {residenceAreas.map((area, index) => (
                  <option key={index} value={area}>
                    {area}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="residenceArea" component="div" className="error-message" />
            </div>

            {/* Residence detail field */}
            <div className="form-group">
              <label htmlFor="residenceDetail">居住详细地</label>
              <Field type="text" name="residenceDetail" className="form-field" />
              <ErrorMessage name="residenceDetail" component="div" className="error-message" />
            </div>

            {/* Intended building field */}
            <div className="form-group">
              <label htmlFor="intendedBuilding">意向楼栋</label>
              <Field
                as="select"
                name="intendedBuilding"
                className="form-field"
                onChange={(e) => handleBuildingChange(e.target.value, setFieldValue)}
              >
                <option value="" disabled>请选择</option>
                {buildingOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="intendedBuilding" component="div" className="error-message" />
            </div>

            {/* Intended card type field */}
            <div className="form-group">
              <label htmlFor="intendedCardType">意向卡种</label>
              <Field
                as="select"
                name="intendedCardType"
                className="form-field"
                onChange={(e) => handleCardTypeChange(e.target.value, setFieldValue)}
              >
                <option value="" disabled>请选择</option>
                {cardTypes.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="intendedCardType" component="div" className="error-message" />
            </div>

            {/* Intended room type field */}
            <div className="form-group">
              <label htmlFor="intendedRoomType">意向房型</label>
              <Field as="select" name="intendedRoomType" className="form-field">
                <option value="" disabled>请选择</option>
                {roomTypes.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="intendedRoomType" component="div" className="error-message" />
            </div>

            {/* Income assets field */}
            <div className="form-group">
              <label htmlFor="incomeAssets">资产</label>
              <Field as="select" name="incomeAssets" className="form-field">
                <option value="" disabled>请选择</option>
                {incomeAssetsOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="incomeAssets" component="div" className="error-message" />
            </div>

            {/* Children field */}
            <div className="form-group">
              <label htmlFor="children">子女情况</label>
              <Field type="text" name="children" className="form-field" />
              <ErrorMessage name="children" component="div" className="error-message" />
            </div>

            {/* Customer concerns field */}
            <div className="form-group">
              <label htmlFor="customerConcerns">客户关注点</label>
              <Field type="text" name="customerConcerns" className="form-field" />
              <ErrorMessage name="customerConcerns" component="div" className="error-message" />
            </div>

            {/* Customer resistance field */}
            <div className="form-group">
              <label htmlFor="customerResistance">客户抗性</label>
              <Field as="select" name="customerResistance" className="form-field">
                <option value="">请选择</option>
                {customerResistanceOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="customerResistance" component="div" className="error-message" />
            </div>

            {/* Visit times field */}
            <div className="form-group">
              <label htmlFor="visitTimes">访问次数</label>
              <Field type="number" name="visitTimes" className="form-field" />
              <ErrorMessage name="visitTimes" component="div" className="error-message" />
            </div>

            {/* Customer level field */}
            <div className="form-group">
              <label htmlFor="customerLevel">客户等级</label>
              <Field as="select" name="customerLevel" className="form-field">
                <option value="" disabled>请选择</option>
                {customerLevelOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="customerLevel" component="div" className="error-message" />
            </div>

            {/* Planner field */}
            <div className="form-group">
              <label htmlFor="planner">规划师</label>
              <Field type="text" name="planner" className="form-field" />
              <ErrorMessage name="planner" component="div" className="error-message" />
            </div>


            {/* Customer status field */}
            <div className="form-group">
              <label htmlFor="customerStatus">客户状态</label>
              <Field as="textarea" name="customerStatus" className="form-field" />
              <ErrorMessage name="customerStatus" component="div" className="error-message" />
            </div>

            {/* Follow-up status field */}
            <div className="form-group">
              <label htmlFor="followUpStatus">跟进状态</label>
              <Field as="select" name="followUpStatus" className="form-field">
                <option value="" disabled>请选择</option>
                {followUpStatusOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="followUpStatus" component="div" className="error-message" />
            </div>

            {/* Follow-up details field */}
            <div className="form-group">
              <label htmlFor="followUpDetails">跟进详细</label>
              <Field as="textarea" name="followUpDetails" className="form-field" />
              <ErrorMessage name="followUpDetails" component="div" className="error-message" />
            </div>

            {/* Remarks field */}
            <div className="form-group">
              <label htmlFor="remarks">备注</label>
              <Field as="textarea" name="remarks" className="form-field" />
              <ErrorMessage name="remarks" component="div" className="error-message" />
            </div>

            {/* Submit button */}
            <div className="form-group">
              <button type="submit" disabled={isSubmitting} className="submit-button">
                {isSubmitting ? '提交中...' : '提交'}
              </button>
            </div>
          </Form>

        )}
      </Formik>
    </div>
  );
};

export default VisitorForm;
