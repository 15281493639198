import Keycloak from 'keycloak-js';

const keycloakConfig = {
  url: 'https://login.qinhegu.cn', // Keycloak 服务器的基本 URL
  realm: 'qinhegu', // 您的 Realm 名称
  clientId: 'qinhegu-X-fronted', // 您的客户端 ID
  checkLoginIframe: false, // 禁用第三方 cookie 检查
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
