import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../../axiosConfig'; // 引入配置好的 axios 实例
import './CustomerLeadsForm.css'; // 引入样式
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

// 表单验证规则
const validationSchema = Yup.object({
    name: Yup.string().max(100, '名字最多100个字符').required('名字是必填项'),
    phone: Yup.string().max(15, '电话号码不能超过15个字符').required('电话号码是必填项'),
    referrer: Yup.string().max(100, '推荐人最多100个字符'),
    transferDemand: Yup.string().max(255, '需求描述不能超过255个字符'),
    intendedVisitTime: Yup.date().required('意向访问时间是必填项'),
    likelyToCloseIn6Months: Yup.boolean(),
    hasPaymentAbilityForSeniorCare: Yup.boolean(),
    notLivingWithChildren: Yup.boolean(),
    age: Yup.number().integer().min(1, '年龄必须是正整数'),
    projectGroup: Yup.string().max(100, '项目组最多100个字符'),
    channel: Yup.string().max(100, '渠道最多100个字符').required('渠道是必填项'),
    customerIds: Yup.array()
        .of(Yup.number())
        .min(1, '至少选择一个客户')
        .required('关联客户是必填项')

});

const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
        age--;
    }
    return age;
};

const determineCustomerLevel = (values) => {
    const { likelyToCloseIn6Months, hasPaymentAbilityForSeniorCare, notLivingWithChildren, age } = values;
    if (!hasPaymentAbilityForSeniorCare) {
        return 'C';
    }
    if (likelyToCloseIn6Months && notLivingWithChildren && age >= 70) {
        return 'A';
    }
    return 'B';
};

const extractBirthDateFromIdentityCard = (identityCard) => {
    if (identityCard.length === 18) {
        const year = identityCard.substring(6, 10);
        const month = identityCard.substring(10, 12);
        const day = identityCard.substring(12, 14);
        return `${year}-${month}-${day}`;
    }
    return null;
};

const CustomerLeadsForm = ({ keycloak }) => {
    const [projectGroupsName, setProjectGroupsName] = useState([]);
    const [customersData, setCustomersData] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    const selectedCustomerIds = useMemo(() => {
        return location.state?.selectedCustomerIds || [];
    }, [location.state?.selectedCustomerIds]);

    const [projectChannels, setProjectChannels] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');

    const fetchProjectGroupsName = useCallback(() => {
        const userGroups = keycloak.tokenParsed?.group;
        const tempProjectGroupsName = userGroups
            .filter(group =>
                group.startsWith(config.projectGroupName) && group !== config.projectGroupName && group.split('/').length === config.projectGroupName.split('/').length + 1
            )
            .map(group => group.slice(config.projectGroupName.length + 1));
        setProjectGroupsName(tempProjectGroupsName);
        setSelectedProject(tempProjectGroupsName[0]);
    }, [keycloak]);

    const fetchProjectIdAndChannels = useCallback(async (projectName) => {
        if (!projectName) return;

        try {
            const response = await axiosInstance.get(`/keycloak/admin/groupIdByName`, {
                params: {
                    parentGroupId: config.projectGroupsId,
                    groupName: projectName
                }
            });
            const groupId = response.data;

            const subGroupsResponse = await axiosInstance.get(`/keycloak/admin/subGroupsName`, {
                params: {
                    groupId: groupId
                }
            });
            const channel = subGroupsResponse.data || [];
            setProjectChannels(channel);
            return channel;
        } catch (error) {
            console.error('获取项目渠道时出错', error);
            return [];
        }
    }, []);

    const fetchSelectedCustomersData = useCallback(async () => {
        try {
            // 使用 Promise.all 并行获取每个客户数据
            const customerDataRequests = selectedCustomerIds.map(id =>
                axiosInstance.get(`/customers/${id}`).then(response => response.data)
            );
            const fetchedCustomers = await Promise.all(customerDataRequests);
            setCustomersData(fetchedCustomers); // 更新客户数据
        } catch (error) {
            console.error('获取选定客户数据时出错', error);
        }
    }, [selectedCustomerIds]);

    useEffect(() => {
        fetchProjectGroupsName();
        if (selectedCustomerIds.length > 0) {
            fetchSelectedCustomersData(); // 获取选中的客户信息
        }
    }, [fetchProjectGroupsName, fetchSelectedCustomersData, selectedCustomerIds]);

    useEffect(() => {
        const fetchChannels = async () => {
            if (selectedProject) {
                const channels = await fetchProjectIdAndChannels(selectedProject);
                setProjectChannels(channels);
            }
        };
        fetchChannels();
    }, [selectedProject, fetchProjectIdAndChannels]);

    const initialValues = {
        name: '',
        phone: '',
        referrer: '',
        transferDemand: '',
        intendedVisitTime: '',
        likelyToCloseIn6Months: false,
        hasPaymentAbilityForSeniorCare: false,
        notLivingWithChildren: false,
        age: '',
        projectGroup: projectGroupsName.length > 0 ? projectGroupsName[0] : '',
        channel: projectChannels.length > 0 ? projectChannels[0] : '',
        customerIds: selectedCustomerIds,
    };
    const handleSelectCustomer = (customer, setFieldValue) => {
        setFieldValue('name', customer.name);
        setFieldValue('phone', customer.phone);
        setFieldValue('projectGroup', customer.channel);
        const birthDate = extractBirthDateFromIdentityCard(customer.identityCard);
        if (birthDate) {
            setFieldValue('age', calculateAge(birthDate));
        } else {
            setFieldValue('age', '');
        }
    };

    return (
        <div className="form-container">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    if (!values.projectGroup && projectGroupsName.length > 0) {
                        values.projectGroup = projectGroupsName[0];
                    }

                    try {
                        values.customerLevel = determineCustomerLevel(values);
                        const response = await axiosInstance.post('/customer-leads', values);
                        console.log('数据提交成功', response.data);
                        // alert('数据提交成功！');
                        navigate('/CustomerLeadsList');
                    } catch (error) {
                        console.error('提交数据时出错', error);
                        alert('提交失败，请重试。');
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                    {({ values, setFieldValue, isSubmitting,  }) => (   

                    <Form className="customer-leads-form">
                        <h2>客资信息录入</h2>

                        <div className="form-group">
                            <h3>关联客户</h3>
                            <FieldArray name="customerIds">
                                {() => (
                                    <div>
                                        {customersData.map((customer) => (
                                            <div key={customer.id} className="customer-select">
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    className="remove-icon"
                                                    onClick={() => {
                                                        setFieldValue('customerIds', values.customerIds.filter(id => id !== customer.id));
                                                        setCustomersData(customersData.filter(c => c.id !== customer.id));
                                                    }}
                                                    style={{ cursor: 'pointer', color: '#e74c3c', marginRight: '8px' }}
                                                />
                                                <label onClick={() => handleSelectCustomer(customer, setFieldValue)} style={{ cursor: 'pointer' }}>
                                                    {customer.id} - {customer.name} - {customer.phone}
                                                </label>
                                            </div>
                                        ))}
                                        <FontAwesomeIcon icon={faPlus}
                                            onClick={() => {
                                                const customerIds = values.customerIds;
                                                console.log('customerIds:', customerIds);
                                                navigate('/customerList', { state: { customerIds } });
                                            }}
                                        />
                                        <ErrorMessage name="customerIds" component="div" className="error-message" />
                                    </div>
                                )}
                            </FieldArray>

                        </div>

                        <div className="form-group">
                            <label htmlFor="name">名字</label>
                            <Field type="text" name="name" className="form-field" />
                            <ErrorMessage name="name" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone">电话号码</label>
                            <Field type="text" name="phone" className="form-field" />
                            <ErrorMessage name="phone" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="referrer">推荐人</label>
                            <Field type="text" name="referrer" className="form-field" />
                            <ErrorMessage name="referrer" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="transferDemand">接送需求</label>
                            <Field type="text" name="transferDemand" className="form-field" />
                            <ErrorMessage name="transferDemand" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="intendedVisitTime">计划到访时间</label>
                            <Field type="datetime-local" name="intendedVisitTime" className="form-field" />
                            <ErrorMessage name="intendedVisitTime" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="age">年龄</label>
                            <Field type="number" name="age" className="form-field" />
                            <ErrorMessage name="age" component="div" className="error-message" />
                        </div>

                        <div className="checkbox-group">
                            <label className="checkbox-label">
                                <Field type="checkbox" name="hasPaymentAbilityForSeniorCare" className="form-checkbox" />
                                <span>有支付养老服务的能力</span>
                            </label>
                            <label className="checkbox-label">
                                <Field type="checkbox" name="likelyToCloseIn6Months" className="form-checkbox" />
                                <span>6个月内可能成交</span>
                            </label>
                            <label className="checkbox-label">
                                <Field type="checkbox" name="notLivingWithChildren" className="form-checkbox" />
                                <span>不与子女同住</span>
                            </label>
                        </div>

                        <div className="form-group">
                            <label htmlFor="customerLevel">客户等级</label>
                            <Field type="text" name="customerLevel" className="form-field" value={determineCustomerLevel(values)} readOnly />
                        </div>

                        <div className="form-group">
                            <label htmlFor="projectGroup">项目组</label>
                            <Field as="select" name="projectGroup" className="form-field" onChange={async (e) => {
                                const selectedValue = e.target.value;
                                setSelectedProject(selectedValue);
                                setFieldValue('projectGroup', selectedValue);                               
                                // 触发获取渠道的函数
                                const channels = await fetchProjectIdAndChannels(selectedValue);
        
                                if (channels && channels.length > 0) {
                                    setFieldValue('channel', channels[0]); 
                                } else {
                                    setFieldValue('channel', '');
                                }
                               
                            }}>                               
                                {projectGroupsName.map((name) => (
                                    <option key={name} value={name}>{name}</option>
                                ))}
                                <option value="非项目渠道">非项目渠道</option>
                            </Field>
                            <ErrorMessage name="projectGroup" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="channel">渠道</label>
                            <Field as="select" name="channel" className="form-field">
                                <option value=''>请选择渠道</option>
                                {projectChannels.map((channel) => (
                                    <option key={channel} value={channel}>{channel}</option>
                                ))}
                            </Field>
                            <ErrorMessage name="channel" component="div" className="error-message" />
                        </div>

                        <button type="submit" disabled={isSubmitting} className="submit-button">
                            {isSubmitting ? '提交中...' : '提交'}
                        </button>
                    </Form>
                    )}
            </Formik>
        </div>
    );
};

export default CustomerLeadsForm;
