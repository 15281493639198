import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../axiosConfig';
import './CustomerLeadsList.css';
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';

// 客户等级判断逻辑
const determineCustomerLevel = (values) => {
  const { likelyToCloseIn6Months, hasPaymentAbilityForSeniorCare, notLivingWithChildren, age } = values;
  if (!hasPaymentAbilityForSeniorCare) {
    return 'C';
  }
  if (likelyToCloseIn6Months && notLivingWithChildren && age >= 70) {
    return 'A';
  }
  return 'B';
};

const CustomerLeadsList = ({ keycloak }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editingCustomerId, setEditingCustomerId] = useState(null);
  const location = useLocation();
  const [editedCustomer, setEditedCustomer] = useState(location.state?.selectedCustomerDetails);
  const [inputPage, setInputPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedCustomerDetails, setSelectedCustomerDetails] = useState(location.state?.selectedCustomerDetails);
  const selectedCustomerIds = location.state?.selectedCustomerIds || [];
  const [isEditing, setIsEditing] = useState(true);
  const [projectGroups, setProjectGroups] = useState([]);

  const navigate = useNavigate();
  const [originalCustomers, setOriginalCustomers] = useState([]);
  const [relatedCustomers, setRelatedCustomers] = useState([]);
  const [projectGroup, setProjectGroup] = useState('');
  const [salesDepartment, setSalesDepartment] = useState('');
  const [planner, setPlanner] = useState('');
  const [validityOfLeads, setValidityOfLeads] = useState('');
  const [availableProjectGroups, setAvailableProjectGroups] = useState([]);
  const [availableSalesDepartments, setAvailableSalesDepartments] = useState([]);
  const [availablePlanners, setAvailablePlanners] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    // 从 keycloak 中提取用户角色和可用的筛选选项
    const userGroups = keycloak.tokenParsed?.group || [];
    let role = '';
    if (userGroups.includes('/数据中心')) {
      role = 'dataCenter';
    } else if (userGroups.some(g => g.startsWith('/项目组/'))) {
      role = 'projectGroup';
      // setProjectGroup(userGroups.find(g => g.startsWith('/项目组/')).split('/')[2]);
    } else if (userGroups.some(g => g.includes('/小组长'))) {
      role = 'salesManager';
      // setSalesDepartment(userGroups.find(g => g.includes('/小组长')).split('/')[2]);
    } else if (userGroups.some(g => g.includes('/规划师'))) {
      role = 'planner';
    }
    setUserRole(role);

    // 获取可用的筛选选项
    fetchAvailableOptions();
  }, [keycloak]);

  const fetchAvailableOptions = async () => {
    try {
      const response = await axiosInstance.get('/customer-leads/available-options');
      setAvailableProjectGroups(response.data.projectGroups);
      setAvailableSalesDepartments(response.data.salesDepartments);
      setAvailablePlanners(response.data.planners);
    } catch (error) {
      console.error('获取可用选项失败', error);
    }
  };

  // 加载新客户数据并更新关联客户列表
  const fetchAndAddSelectedCustomers = async () => {
    try {
      // 通过 selectedCustomerIds 获取客户数据
      const newCustomers = await Promise.all(
        selectedCustomerIds.map(async (id) => {
          const response = await axiosInstance.get(`/customers/${id}`);
          return response.data;
        })
      );

      // 将 selectedCustomerDetails.customers 合并到关联客户列表中
      const existingCustomers = selectedCustomerDetails?.customers || [];
      const allCustomers = [...existingCustomers, ...newCustomers];

      // 去重（基于客户 ID）
      const customerMap = new Map(
        allCustomers.map((customer) => [customer.id, customer])
      );

      // 更新状态
      setRelatedCustomers(Array.from(customerMap.values()));
    } catch (error) {
      console.error("获取选中客户数据失败：", error);
    }
  };
  // 在组件挂载后或 selectedCustomerIds 变化时调用
  useEffect(() => {
    if (selectedCustomerIds && selectedCustomerIds.length > 0) {
      fetchAndAddSelectedCustomers();
    }
  }, [selectedCustomerIds, selectedCustomerDetails]);

  useEffect(() => {
    if (selectedCustomerDetails?.customers) {
      setOriginalCustomers(selectedCustomerDetails.customers);
      setRelatedCustomers(selectedCustomerDetails.customers);
    }
  }, [selectedCustomerDetails]);

  const handleAddCustomer = () => {
    navigate('/CustomerListLeadUpload', { state: { handle: 'add', selectedCustomerDetails } });
  };
  // 接收从另一个页面传回的数据
  useEffect(() => {
    if (location.state?.newCustomers) {
      setRelatedCustomers((prevCustomers) => [
        ...prevCustomers,
        ...location.state.newCustomers,
      ]);
    }
  }, [location.state]);

  useEffect(() => {
    // 从 keycloak 中提取项目组
    const userGroups = keycloak.tokenParsed?.group || [];
    const tempProjectGroupsName = userGroups
      .filter(group =>
        group.startsWith(config.projectGroupName) &&
        group !== config.projectGroupName &&
        group.split('/').length === config.projectGroupName.split('/').length + 1
      )
      .map(group => group.slice(config.projectGroupName.length + 1));

    setProjectGroups(tempProjectGroupsName);
  }, [keycloak]);

  const handleViewDetails = (customer) => {
    setSelectedCustomerDetails(customer);
    setEditedCustomer(customer); // 初始化编辑对象
    setIsEditing(false); // 默认非编辑模式
  };

  const fieldTranslations = {
    id: "客户编号",
    dateTime: "创建时间",
    name: "姓名",
    phone: "电话",
    referrer: "介绍人",
    transferDemand: "接送需求",
    intendedVisitTime: "意向访问时间",
    likelyToCloseIn6Months: "6个月内有望成交",
    hasPaymentAbilityForSeniorCare: "有养老消费买单能力",
    notLivingWithChildren: "不与子女生活",
    age: "年龄",
    customerLevel: "客户等级",
    projectGroup: "项目组",
    validityOfLeads: "客资有效性",
    customers: "关联客户",
    uploader: "上传者",
    uploadTime: "上传时间",
    leadDistributionDepartment: "分配部门",
    leadDistributionPlanner: "分配规划师"
  };

  const formatBoolean = (value) => {
    if (value === true) return "是";
    if (value === false) return "否";
    return "无"; // 如果值为 null 或 undefined
  };

  const handleCancel = () => {
    setRelatedCustomers(originalCustomers); // 恢复初始数据
    setIsEditing(false); // 退出编辑模式
  };
  const handleRemoveCustomer = (id) => {
    setRelatedCustomers((prevCustomers) =>
      prevCustomers.filter((customer) => customer.id !== id)
    );
  };


  const handleCloseCustomerLead = () => {
    setRelatedCustomers(originalCustomers);
    setSelectedCustomerDetails(null);
  };

  const renderCustomerDetails = () => {
    if (!selectedCustomerDetails) return null;

    const { customers, ...customerInfo } = selectedCustomerDetails;

    const editableFields = [
      "referrer",
      "transferDemand",
      "intendedVisitTime",
      "hasPaymentAbilityForSeniorCare",
      "notLivingWithChildren",
      "likelyToCloseIn6Months",
      "age",
      "projectGroup",
      "validityOfLeads",
    ];

    return (
      <div className="customer-details-modal">
        <div className="customer-details-content">
          <h2>客资详细信息</h2>
          <button className="button close-button" onClick={() => handleCloseCustomerLead()}>关闭</button>

          {isEditing ? (
            editedCustomer ? (
              <div>
                <h3>编辑客户信息</h3>
                <ul>
                  {Object.entries(customerInfo).map(([key, value]) => (
                    <li key={key}>
                      <strong>{fieldTranslations[key] || key}:</strong>{" "}
                      {editableFields.includes(key) ? (
                        key === "projectGroup" ? (
                          <select
                            value={editedCustomer.projectGroup || ""}
                            onChange={(e) => handleFieldChange(key, e.target.value)}
                          >
                            <option value="" disabled>请选择项目组</option>
                            {projectGroups.map(group => (
                              <option key={group} value={group}>
                                {group}
                              </option>
                            ))}
                          </select>
                        ) : key === "intendedVisitTime" ? (
                          <input
                            type="datetime-local"
                            value={
                              editedCustomer[key]
                                ? new Date(editedCustomer[key])
                                  .toISOString()
                                  .slice(0, -1)
                                : ""
                            }
                            onChange={(e) => handleFieldChange(key, e.target.value)}
                          />
                        ) : typeof value === "boolean" ? (
                          <input
                            type="checkbox"
                            checked={editedCustomer[key] || false}
                            onChange={(e) => handleFieldChange(key, e.target.checked)}
                          />
                        ) : (
                          <input
                            type="text"
                            value={editedCustomer[key] || ""}
                            onChange={(e) => handleFieldChange(key, e.target.value)}
                          />
                        )
                      ) : key === "customerLevel" ? (
                        <span>{editedCustomer.customerLevel || "无"}</span>
                      ) : (
                        typeof value === "boolean"
                          ? formatBoolean(value)
                          : value || "无"
                      )}
                    </li>
                  ))}
                </ul>
                <div className="modal-actions">
                  <button onClick={handleSave}>保存</button>
                  <button onClick={() => handleCancel()}>取消</button>
                </div>
              </div>
            ) : (
              <p>加载中...</p>
            )
          ) : (
            <div>
              <h3>客户信息</h3>
              <ul>
                {Object.entries(customerInfo).map(([key, value]) => (
                  <li key={key}>
                    <strong>{fieldTranslations[key] || key}:</strong>{" "}
                    {typeof value === "boolean" ? formatBoolean(value) : value || "无"}
                  </li>
                ))}
              </ul>

              <button className="button edit-button" onClick={() => setIsEditing(true)}>编辑</button>
            </div>
          )}

          <h3>关联客户列表</h3>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>身份证号</th>
                <th>姓名</th>
                <th>电话</th>
                <th>渠道</th>
                <th>上传人</th>
                <th>上传时间</th>
                {isEditing && <th>操作</th>}
              </tr>
            </thead>
            <tbody>
              {relatedCustomers.map((cust) => (
                <tr key={cust.id}>
                  <td>{cust.id}</td>
                  <td>{cust.identityCard || "无"}</td>
                  <td>{cust.name}</td>
                  <td>{cust.phone}</td>
                  <td>{cust.channel}</td>
                  <td>{cust.uploader}</td>
                  <td>{new Date(cust.uploadTime).toLocaleString()}</td>
                  {isEditing && (
                    <td>
                      {/* 可添加删除按钮 */}
                      <button className="button delete-button" onClick={() => handleRemoveCustomer(cust.id)}>删除</button>
                    </td>
                  )}
                </tr>
              ))}
              {isEditing && (
                <tr>
                  <td colSpan="8" onClick={handleAddCustomer}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      onClick={handleAddCustomer}
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const fetchCustomerLeads = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/customer-leads', {
        params: {
          page: page - 1,
          startDate: startDate  || undefined,
          endDate: endDate || undefined,
          searchTerm: searchTerm  || undefined,
          projectGroup: projectGroup || undefined,
          salesDepartment: salesDepartment || undefined,
          planner: planner || undefined,
          validityOfLeads: validityOfLeads || undefined,
        }
      });
      setCustomers(response.data.content);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalElements);
    } catch (error) {
      console.error('获取客户数据失败', error);
    }
  }, [startDate, endDate, searchTerm, page, projectGroup, salesDepartment, planner, validityOfLeads]);

  useEffect(() => {
    fetchCustomerLeads();
  }, [fetchCustomerLeads]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleFieldChange = (field, value) => {
    const updatedCustomer = { ...editedCustomer, [field]: value };

    // 动态计算客户等级
    updatedCustomer.customerLevel = determineCustomerLevel(updatedCustomer);

    setEditedCustomer(updatedCustomer);
  };



  const handleSave = async () => {
    try {
      // 检查是否有新增的客户
      const updatedCustomerData = {
        ...editedCustomer,
        customers: relatedCustomers, // 确保字段名称与后端一致
      };

      // 调用后端接口
      await axiosInstance.put(`/customer-leads/${editedCustomer.id}`, updatedCustomerData);

      // 更新状态
      setSelectedCustomerDetails(updatedCustomerData);
      setOriginalCustomers(relatedCustomers); // 更新原始数据
      setIsEditing(false); // 退出编辑模式
      // alert('保存成功！');
    } catch (error) {
      console.error('保存修改时出错', error);
      alert('保存失败，请稍后重试！');
    }
  };



  const handleDelete = async (customerId) => {
    if (window.confirm('是否删除该条记录')) {
      try {
        await axiosInstance.delete(`/customer-leads/${customerId}`);
        fetchCustomerLeads();
      } catch (error) {
        console.error('删除时出错', error);
      }
    };
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, page - 4);
    let endPage = Math.min(totalPages, page + 5);

    if (endPage - startPage < 9) {
      startPage = Math.max(1, endPage - 9);
      endPage = Math.min(totalPages, startPage + 9);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={i === page ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };
  const handleJumpToPage = () => {
    if (inputPage >= 1 && inputPage <= totalPages) {
      setPage(inputPage);
    } else {
      alert(`请输入有效的页码（1到${totalPages}）`);
    }
  };

  const projectGroupOrder = ['项目一', '项目二', '项目三', '项目四', '非项目渠道', '未知'];
  const salesTeamOrder = ['营销服务一部', '营销服务二部', '营销服务三部', '未分配']
  const sortedProjectGroups = Array.isArray(availableProjectGroups)?availableProjectGroups.slice().sort((a, b) => {
    return projectGroupOrder.indexOf(a) - projectGroupOrder.indexOf(b);
  }) : [];
  const sortedSalesTeams = Array.isArray(availableSalesDepartments)?availableSalesDepartments.slice().sort((a, b) => {
    return salesTeamOrder.indexOf(a) - salesTeamOrder.indexOf(b);
  }) : [];


  return (
    <div className="search-page-container">
      <h1>客资数据查询</h1>
      <div className="filter-container">
        <div>
        <input
          type="text"
          placeholder="根据ID、姓名或手机号搜索"
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
        <input
          type="datetime-local"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="datetime-local"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        </div>
        <div>
        {(userRole === 'dataCenter' || userRole === 'salesManager' || userRole === 'planner') && (
          <select value={projectGroup} onChange={(e) => setProjectGroup(e.target.value)}>
            <option value="">全部项目组</option>
            {sortedProjectGroups?.map(group => (
              <option key={group} value={group}>{group}</option>
            ))}
          </select>
        )}
        {(userRole === 'dataCenter' || userRole === 'projectGroup') &&
          (<select value={salesDepartment} onChange={(e) => setSalesDepartment(e.target.value)}>
            <option value="">全部销售部门</option>
            {sortedSalesTeams?.map(dept => (
              <option key={dept} value={dept}>{dept ? dept : '未分配'}</option>
            ))}
          </select>
          )}
        {(userRole === 'dataCenter' || userRole === 'projectGroup' || userRole === 'salesManager') &&
          (<select value={planner} onChange={(e) => setPlanner(e.target.value)}>
            <option value="">全部规划师</option>
            {availablePlanners?.map(planner => (
              <option key={planner} value={planner}>{planner ? planner : '未分配'}</option>
            ))}
          </select>
          )}
        <select value={validityOfLeads} onChange={(e) => setValidityOfLeads(e.target.value)}>
          <option value="">全部客资有效性</option>
          <option value="true">有效</option>
          <option value="false">无效</option>
        </select>
      </div>
      </div>
      <div className="table-scrollable">
        <table className="customer-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>姓名</th>
              <th>电话</th>
              <th>意向访问时间</th>
              {/* {editingCustomerId && (
                <>
                  <th>介绍人</th>
                  <th>6个月内有望成交</th>
                  <th>有养老消费买单能力</th>
                  <th>不与子女生活在一起</th>
                </>
              )} */}
              <th>年龄</th>
              <th>客户等级</th>
              <th>项目组</th>
              <th>分配部门</th>
              <th>规划师</th>
              <th>客资有效性</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer) => (
              <tr key={customer.id}>
                <td>{customer.id}</td>
                <td>
                  {customer.name}
                </td>
                <td>
                  {customer.phone}
                </td>
                <td>
                  {new Date(customer.intendedVisitTime).toLocaleString()}
                </td>
                <td>
                  {customer.age}
                </td>
                <td>
                  {customer.customerLevel}
                </td>
                <td>
                  {customer.projectGroup}
                </td>
                <td>
                  {customer.leadDistributionDepartment ? customer.leadDistributionDepartment : '未分配'}
                </td>
                <td>
                  {customer.leadDistributionPlanner ? customer.leadDistributionPlanner : '未分配'}
                </td>
                <td>
                  {customer.validityOfLeads ? '是' : '否'}
                </td>
                <td>
                  {editingCustomerId === customer.id ? (
                    <>
                      <button onClick={handleSave} className="action-button">保存</button>
                      <button onClick={() => setEditingCustomerId(null)} className="action-button">取消</button>
                    </>
                  ) : (
                    <>
                      <button onClick={() => handleViewDetails(customer)} className="action-button">查看详细</button>
                      <button onClick={() => handleDelete(customer.id)} className="action-button">删除</button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <div className="pagination">
          <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
            上一页
          </button>
          {renderPageNumbers()}
          <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
            下一页
          </button>
        </div>
        <div className="pagination-info">
          <span>共 {totalItems} 条记录, {totalPages} 页</span>
          <div className="page-jump">
            <input
              type="number"
              min="1"
              max={totalPages}
              value={inputPage}
              onChange={(e) => setInputPage(Number(e.target.value) || 1)}
            />
            <button onClick={handleJumpToPage}>跳转</button>
          </div>
        </div>
      </div>
      {renderCustomerDetails()}
    </div>
  );
};

export default CustomerLeadsList;