import axios from 'axios';
import keycloak from './keycloak'; // 导入初始化的 Keycloak 实例
import config from './config';

// const axiosInstance = axios.create({
//   baseURL: 'https://xbackend.qinhegu.cn/api',
//   withCredentials: true,
// });

const axiosInstance = axios.create({
  baseURL: config.apiUrl + '/api',
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (keycloak && keycloak.authenticated) {
      try {
        await keycloak.updateToken(5); // 刷新令牌，如果令牌在5秒内过期
        config.headers.Authorization = `Bearer ${keycloak.token}`;
      } catch (error) {
        console.error('Failed to refresh token', error);
        keycloak.login(); // 如果刷新令牌失败，重定向到登录页面
      }
    } else {
      console.warn('User is not authenticated. Redirecting to login.');
      keycloak.login(); // 如果用户未认证，重定向到登录页面
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
