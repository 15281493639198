// LeadDistributionForm.js
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../../axiosConfig'; // 引入配置好的 axios 实例
import './LeadDistributionForm.css'; // 引入样式
import { useNavigate } from 'react-router-dom';

// 表单验证规则
const validationSchema = Yup.object({
    // date: Yup.date().required('日期是必填项'),
    customerLeadId: Yup.number().required('客户ID是必填项').integer('客户ID必须是整数'),
    salesTeam: Yup.string().max(100, '销售团队名称最多100个字符').required('销售团队是必填项')
});

// 初始表单值
const initialValues = {
    date: '',
    customerLeadId: '',
    salesTeam: ''
};

const LeadDistributionForm = () => {
    const [customerLeads, setCustomerLeads] = useState([]); // 存储客户列表数据
    const [salesTeams, setSalesTeams] = useState([]);
    const navigate = useNavigate();

    // 在页面加载时获取客户列表
    useEffect(() => {
        const fetchCustomerLeads = async () => {
            try {
                const response = await axiosInstance.get('/customer-leads/leadsNotInDistribution'); // 假设API路径为 /customer-leads
                setCustomerLeads(response.data || []); // 设置获取到的客户列表数据
            } catch (error) {
                console.error('获取客户列表时出错', error);
                // alert('获取客户列表失败，请刷新页面重试');
            }
            try {
                const response = await axiosInstance.get('/keycloak/admin/subGroups', {
                    params: {
                        groupId: 'b7236ada-8c75-4f5a-972d-159ec3f3bed5'
                    }
                });
                setSalesTeams(response.data);
            } catch (error){
                console.error('获取销售部门列表时出错', error);
                // alert('获取销售部门列表失败，请刷新页面重试')
            }
        };

        fetchCustomerLeads();
    }, []);

    return (
        <div className="form-container">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    try {
                        const response = await axiosInstance.post('/lead-distribution', values); // 发送请求
                        console.log('数据提交成功', response.data);
                        // alert('数据提交成功！');
                        navigate('/LeadDistributionList'); // 提交成功后跳转到分配列表页面
                    } catch (error) {
                        console.error('提交数据时出错', error);
                        alert('提交失败，请重试。');
                    } finally {
                        setSubmitting(false); // 设置提交状态为 false
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form className="lead-distribution-form">
                        <h2>客资分配信息录入</h2>

                        {/* <div className="form-group">
                            <label htmlFor="date">分配日期</label>
                            <Field type="datetime-local" name="date" className="form-field" />
                            <ErrorMessage name="date" component="div" className="error-message" />
                        </div> */}

                        <div className="form-group">
                            <label htmlFor="customerLeadId">客户ID</label>
                            <Field as="select" name="customerLeadId" className="form-field">
                                <option value="">请选择客户</option>
                                {customerLeads.map((lead) => (
                                    <option key={lead.id} value={lead.id}>
                                        {lead.name} (ID: {lead.id})
                                    </option>
                                ))}
                            </Field>
                            <ErrorMessage name="customerLeadId" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="salesTeam">销售团队</label>
                            <Field as="select" name="salesTeam" className="form-field">
                                <option value="">请选择销售部门</option>
                                {salesTeams.map((team) => (
                                    <option key={team.id} value={team.name}>{team.name}</option>
                                ))}
                            </Field>
                            <ErrorMessage name="salesTeam" component="div" className="error-message" />
                        </div>

                        <button type="submit" disabled={isSubmitting} className="submit-button">
                            {isSubmitting ? '提交中...' : '提交'}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default LeadDistributionForm;
