import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosConfig';
import { useNavigate } from 'react-router-dom';
import './DealEntryForm.css';

const DealEntryForm = () => {
  const [form, setForm] = useState({
    property: '',
    discountPolicy: '',
    customerName: '',
    birthDate: '',
    dealDate: '',
    introducer: '',
    visitorId: '',
    trialStayId: '',
    dealAmount: '',
    paymentMethod: '',
    amountPaid: '',
    amountDue: '',
    followUpPlan: ''
  });

  const [visitors, setVisitors] = useState([]);
  const [planners, setPlanners] = useState([]);
  const [selectedVisitor, setSelectedVisitor] = useState(null);
  const [filterDate, setFilterDate] = useState('');
  const [filterPlanner, setFilterPlanner] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlanners = async () => {
      try {
        const response = await axiosInstance.get('/visitors/planners');
        setPlanners(response.data);
      } catch (error) {
        console.error('Error fetching planners:', error);
      }
    };

    fetchPlanners();
  }, []);

  useEffect(() => {
    const fetchVisitors = async () => {
      try {
        const response = await axiosInstance.get('/visitors/byplanner', {
          params: {
            visit_time: filterDate || undefined,
            planner: filterPlanner || undefined,
            search: searchKeyword || undefined,
          },
        });
        setVisitors(response.data);
      } catch (error) {
        console.error('Error fetching visitors:', error);
      }
    };

    fetchVisitors();
  }, [filterDate, filterPlanner, searchKeyword]);

  const handleVisitorSelect = async (visitor) => {
    setSelectedVisitor(visitor);
    setForm((prevForm) => ({ ...prevForm, visitorId: visitor.id }));
    
    try {
      const response = await axiosInstance.get(`/trial_residences?visitorId=${visitor.id}`);
      if (response.status === 200 && response.data) {
        const trialResidence = response.data;
        setForm((prevForm) => ({
          ...prevForm,
          trialStayId: trialResidence.id ? trialResidence.id : ''
        }));
      } else {
        setForm((prevForm) => ({
          ...prevForm,
          trialStayId: ''
        }));
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setForm((prevForm) => ({
          ...prevForm,
          trialStayId: ''
        }));
      } else {
        console.error('Error fetching trial residence:', error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    setForm((prevForm) => {
      const updatedForm = { ...prevForm, [name]: value };
      
      if (name === 'dealAmount' || name === 'amountPaid') {
        const dealAmount = parseFloat(updatedForm.dealAmount) || 0;
        const amountPaid = parseFloat(updatedForm.amountPaid) || 0;
        updatedForm.amountDue = (dealAmount - amountPaid).toFixed(2);
      }

      return updatedForm;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form data:", form);

    const dealData = {
      property: form.property,
      discountPolicy: form.discountPolicy,
      customerName: form.customerName,
      birthDate: form.birthDate ? new Date(form.birthDate).getTime() : null,
      dealDate: form.dealDate ? new Date(form.dealDate).getTime() : null,
      introducer: form.introducer,
      dealAmount: parseFloat(form.dealAmount),
      paymentMethod: form.paymentMethod,
      amountPaid: parseFloat(form.amountPaid),
      amountDue: parseFloat(form.amountDue),
      visitor: form.visitorId ? parseInt(form.visitorId) : null,
      trialResidence: form.trialStayId ? parseInt(form.trialStayId) : null,
      followUpPlan: form.followUpPlan
    };

    try {
      await axiosInstance.post('/deals', dealData);
      alert('成交记录添加成功');
      navigate('/deal');
    } catch (error) {
      console.error('Error creating deal:', error);
    }
  };

  return (
    <div className="deal-entry-form">
      <h2>添加成交记录</h2>
      <form onSubmit={handleSubmit}>
        <div className="filter-container">
          <input
            type="date"
            value={filterDate}
            onChange={(e) => setFilterDate(e.target.value)}
            placeholder="筛选日期"
          />
          <select
            value={filterPlanner}
            onChange={(e) => setFilterPlanner(e.target.value)}
          >
            <option value="">所有规划师</option>
            {planners.map((planner, index) => (
              <option key={index} value={planner}>{planner}</option>
            ))}
          </select>
          <input
            type="text"
            placeholder="搜索访客"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>选择访客:</label>
          <select
            value={selectedVisitor ? selectedVisitor.id : ''}
            onChange={(e) => handleVisitorSelect(visitors.find(v => v.id === parseInt(e.target.value)))}
            required
          >
            <option value="">请选择访客</option>
            {visitors.map(visitor => (
              <option key={visitor.id} value={visitor.id}>
                {visitor.name} - {new Date(visitor.visitTime).toLocaleDateString()} - {visitor.planner}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>成交房源:</label>
          <input
            name="property"
            placeholder="成交房源"
            value={form.property}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>优惠政策:</label>
          <input
            name="discountPolicy"
            placeholder="优惠政策"
            value={form.discountPolicy}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>客户姓名:</label>
          <input
            name="customerName"
            placeholder="客户姓名"
            value={form.customerName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>出生日期:</label>
          <input
            name="birthDate"
            type="date"
            value={form.birthDate}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>成交日期:</label>
          <input
            name="dealDate"
            type="date"
            value={form.dealDate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>介绍人:</label>
          <input
            name="introducer"
            placeholder="介绍人"
            value={form.introducer}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>成交金额:</label>
          <input
            name="dealAmount"
            type="number"
            placeholder="成交金额"
            value={form.dealAmount}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>支付方式:</label>
          <input
            name="paymentMethod"
            placeholder="支付方式"
            value={form.paymentMethod}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>已付款:</label>
          <input
            name="amountPaid"
            type="number"
            placeholder="已付款"
            value={form.amountPaid}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>未付款:</label>
          <input
            name="amountDue"
            type="number"
            placeholder="未付款"
            value={form.amountDue}
            readOnly
          />
        </div>
        <div className="form-group">
          <label>试入住:</label>
          <input
            name="trialStayId"
            value={form.trialStayId ? "是" : "否"}
            readOnly
          />
        </div>
        <div className="form-group">
          <label>后续跟进计划:</label>
          <textarea
            name="followUpPlan"
            value={form.followUpPlan}
            onChange={handleChange}
          />
        </div>
        <button type="submit">提交</button>
      </form>
    </div>
  );
};

export default DealEntryForm;
