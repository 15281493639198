import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = ({ keycloak }) => {
  const [dealOpen, setDealOpen] = useState(false);
  const [customerOpen, setCustomerOpen] = useState(false);
  const [customerLeadOpen, setCustomerLeadOpen] = useState(false);
  const [leadDistributionOpen, setLeadDistributionOpen] = useState(false);
  const [pendingLeadOpen, setPendingLeadOpen] = useState(false);
  const [visitorOpen, setVisitorOpen] = useState(false);


  const toggleDeal = () => setDealOpen(!dealOpen);
  const toggleCustomer = () => setCustomerOpen(!customerOpen);
  const toggleCustomerLead = () => setCustomerLeadOpen(!customerLeadOpen);
  const toggleLeadDistribution = () => setLeadDistributionOpen(!leadDistributionOpen);
  const togglePendingLead = () => setPendingLeadOpen(!pendingLeadOpen);
  const toggleVisitor = () => setVisitorOpen(!visitorOpen);
  const userRoles = keycloak.tokenParsed?.realm_access?.roles || [];
  const hasCustomerGetRole = userRoles.includes('CustomerGet');
  const hasCustomerPostRole = userRoles.includes('CustomerPost');
  const hasCustomerLeadGetRole = userRoles.includes('CustomerLeadGet');
  const hasCustomerLeadPostRole = userRoles.includes('CustomerLeadPost');
  const hasLeadDistributionGetRole = userRoles.includes('LeadDistributionGet');
  const hasLeadDistributionPostRole = userRoles.includes('LeadDistributionPost');
  const hasPendingCustomerLeadGetRole = userRoles.includes('PendingCustomerLeadGet');
  const hasPendingCustomerLeadPostRole = userRoles.includes('PendingCustomerLeadPost');
  const hasVisitorGetRole = userRoles.includes('VisitorGet');
  const hasVisitorPostRole = userRoles.includes('VisitorPost');
  const hasDealGetRole = userRoles.includes('DealGet');
  const hasDealPostRole = userRoles.includes('DealPost');
  const isShowCustomerModal = hasCustomerGetRole || hasCustomerPostRole;
  const isShowCustomerLeadModal = hasCustomerLeadGetRole || hasCustomerLeadPostRole;
  const isShowLeadDistributionModal = hasLeadDistributionGetRole || hasLeadDistributionPostRole;
  const isShowPendingCustomerLeadModal = hasPendingCustomerLeadGetRole || hasPendingCustomerLeadPostRole;
  const isShowVisitorModal = hasVisitorGetRole || hasVisitorPostRole;
  const isShowDealModal = hasDealGetRole || hasDealPostRole;

  return (
    <div className="sidebar">
      <ul>
        {isShowCustomerModal && (<li>
          <div className="menu-item" onClick={toggleCustomer}>
            <span>客户信息模块</span>
            <span className={`arrow ${customerOpen ? 'open' : ''}`}></span>
          </div>
          {customerOpen && (
            <ul className="submenu">
              {hasCustomerGetRole && (<li>
                <NavLink to="/CustomerList" className={({ isActive }) => (isActive ? 'active' : '')}>
                  查询
                </NavLink>
              </li>)}
              {hasCustomerPostRole && (<li>
                <NavLink to="/CustomerForm" className={({ isActive }) => (isActive ? 'active' : '')}>
                  录入
                </NavLink>
              </li>)}
            </ul>
          )}
        </li>)}
        {isShowCustomerLeadModal && (<li>
          <div className="menu-item" onClick={toggleCustomerLead}>
            <span>客资记录模块</span>
            <span className={`arrow ${customerLeadOpen ? 'open' : ''}`}></span>
          </div>
          {customerLeadOpen && (
            <ul className="submenu">
              {hasCustomerGetRole && (<li>
                <NavLink to="/CustomerLeadsList" className={({ isActive }) => (isActive ? 'active' : '')}>
                  查询
                </NavLink>
              </li>)}
              {hasCustomerPostRole && (<li>
                <NavLink to="/CustomerListLeadUpload" className={({ isActive }) => (isActive ? 'active' : '')}>
                  录入
                </NavLink>
              </li>)}
            </ul>
          )}
        </li>)}
        {isShowLeadDistributionModal && (<li>
          <div className="menu-item" onClick={toggleLeadDistribution}>
            <span>客资分配模块</span>
            <span className={`arrow ${leadDistributionOpen ? 'open' : ''}`}></span>
          </div>
          {leadDistributionOpen && (
            <ul className="submenu">
              {hasLeadDistributionGetRole && (<li>
                <NavLink to="/LeadDistributionList" className={({ isActive }) => (isActive ? 'active' : '')}>
                  查询
                </NavLink>
              </li>)}
              {hasLeadDistributionPostRole && (<li>
                <NavLink to="/LeadDistributionForm" className={({ isActive }) => (isActive ? 'active' : '')}>
                  录入
                </NavLink>
              </li>)}
            </ul>
          )}
        </li>)}
        {isShowPendingCustomerLeadModal && (<li>
          <div className="menu-item" onClick={togglePendingLead}>
            <span>补客资模块</span>
            <span className={`arrow ${pendingLeadOpen ? 'open' : ''}`}></span>
          </div>
          {pendingLeadOpen && (
            <ul className="submenu">
              {hasPendingCustomerLeadGetRole && (<li>
                <NavLink to="/PendingCustomerLeadList" className={({ isActive }) => (isActive ? 'active' : '')}>
                  查询
                </NavLink>
              </li>)}
              {hasPendingCustomerLeadPostRole && (<li>
                <NavLink to="/PendingCustomerLeadForm" className={({ isActive }) => (isActive ? 'active' : '')}>
                  录入
                </NavLink>
              </li>)}
            </ul>
          )}
        </li>)}
        {isShowVisitorModal && (<li>
          <div className="menu-item" onClick={toggleVisitor}>
            <span>访客记录模块</span>
            <span className={`arrow ${visitorOpen ? 'open' : ''}`}></span>
          </div>
          {visitorOpen && (
            <ul className="submenu">
              {hasVisitorGetRole && (<li>
                <NavLink to="/visitorList" className={({ isActive }) => (isActive ? 'active' : '')}>
                  查询
                </NavLink>
              </li>)}
              {hasVisitorPostRole && (<li>
                <NavLink to="/visitorForm" className={({ isActive }) => (isActive ? 'active' : '')}>
                  录入
                </NavLink>
              </li>)}
            </ul>
          )}
        </li>)}
        {isShowDealModal && (<li>
          <div className="menu-item" onClick={toggleDeal}>
            <span>成交模块</span>
            <span className={`arrow ${dealOpen ? 'open' : ''}`}></span>
          </div>
          {dealOpen && (
            <ul className="submenu">
              {hasDealGetRole && (<li>
                <NavLink to="/deal" className={({ isActive }) => (isActive ? 'active' : '')}>
                  查询
                </NavLink>
              </li>)}
              {hasDealPostRole && (<li>
                <NavLink to="/add-deal" className={({ isActive }) => (isActive ? 'active' : '')}>
                  录入
                </NavLink>
              </li>)}
            </ul>
          )}
        </li>)}
      </ul>
    </div>
  );
};

export default Sidebar;
