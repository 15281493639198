import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../../axiosConfig';
import './PendingCustomerLeadForm.css';
import { useNavigate } from 'react-router-dom';

// 表单验证规则
const validationSchema = Yup.object({
    invalidLeadId: Yup.string().required('无效客资是必填项'),
    projectGroup: Yup.string().max(100, '项目组名称最多100个字符').required('项目组是必填项'),
    salesTeam: Yup.string().max(100, '销售团队名称最多100个字符').required('销售团队是必填项'),
    status: Yup.string().required('状态是必填项')
});

// 初始表单值
const initialValues = {
    date: '',
    projectGroup: '',
    salesTeam: '',
    supplementaryNotes: '',
    status: '待补',  // Default status
    invalidLeadId: '',
    supplementedLeadId: '',
};

const PendingCustomerLeadForm = () => {
    const [invalidCustomerLeads, setInvalidCustomerLeads] = useState([]);
    const [validCustomerLeads, setValidCustomerLeads] = useState([]);
    const [salesTeams, setSalesTeams] = useState([]);
    const [projectGroups, setProjectGroups] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCustomerLeads = async () => {
            try {
                const invalidCustomerResponse = await axiosInstance.get('/customer-leads/leadsByValidityIsInPending', {
                    params: { isValid: false }
                });

                setInvalidCustomerLeads(invalidCustomerResponse.data || []);

            } catch (error) {
                console.error('获取数据时出错', error);
                // alert('获取数据失败，请刷新页面重试');
            }
        };

        fetchCustomerLeads();
    }, []);

    const fetchLeadDetails = async (leadId, setFieldValue) => {
        try {
            const response = await axiosInstance.get(`/customer-leads/lead-details/${leadId}`);
            if (response.data) {
                const projectGroup = response.data.projectGroup;
                const salesTeam = response.data.salesTeam;

                setProjectGroups([{ id: 'default-project-group', name: projectGroup }]);
                setSalesTeams([{ id: 'default-sales-team', name: salesTeam }]);

                setFieldValue('projectGroup', projectGroup, false);
                setFieldValue('salesTeam', salesTeam, false);

                const validCustomerResponse = await axiosInstance.get('/customer-leads/leadsByValidityIsInPending', {
                    params: {
                        isValid: true,
                        projectGroup: projectGroup
                    }                   
            });
            setValidCustomerLeads(validCustomerResponse.data || []);
        }        
        } catch (error) {
            setFieldValue('projectGroup', "", false);
            setFieldValue('salesTeam', "", false);
            console.error("获取客资详情时出错", error);
        }
    };

    // const fetchSupplementLeads = async (projectGroups) => {

    // }

    return (
        <div className="form-container">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    try {
                        const response = await axiosInstance.post('/pending-customer-leads', values);
                        console.log('数据提交成功', response.data);
                        // alert('数据提交成功！');
                        navigate('/PendingCustomerLeadList');
                    } catch (error) {
                        console.error('提交数据时出错', error);
                        alert('提交失败，请重试。');
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form className="pending-customer-lead-form">
                        <h2>补客资信息录入</h2>

                        <div className="form-group">
                            <label htmlFor="invalidLeadId">无效客资ID</label>
                            <Field as="select" name="invalidLeadId" className="form-field"
                                onChange={e => {
                                    const leadId = e.target.value;
                                    setFieldValue('invalidLeadId', leadId);
                                    if (leadId) {
                                        fetchLeadDetails(leadId, setFieldValue);
                                        // await fetchSupplementLeads(projectGroups);
                                    } else {
                                        setFieldValue('projectGroup', '', false);
                                        setFieldValue('salesTeam', '', false);
                                        setProjectGroups([]);
                                        setSalesTeams([]);
                                    }
                                }}
                            >
                                <option value="">请选择无效客资</option>
                                {invalidCustomerLeads.map((lead) => (
                                    <option key={lead.id} value={lead.id}>
                                        {lead.name} (ID: {lead.id})
                                    </option>
                                ))}
                            </Field>
                            <ErrorMessage name="invalidLeadId" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="projectGroup">项目组</label>
                            <Field as="select" name="projectGroup" className="form-field">
                                <option value="" disabled>请选择项目组</option>
                                {projectGroups.map((group) => (
                                    <option key={group.id} value={group.name}>
                                        {group.name}
                                    </option>
                                ))}
                            </Field>
                            <ErrorMessage name="projectGroup" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="salesTeam">销售团队</label>
                            <Field as="select" name="salesTeam" className="form-field">
                                <option value="" disabled>请选择销售团队</option>
                                {salesTeams.map((team) => (
                                    <option key={team.id} value={team.name}>
                                        {team.name}
                                    </option>
                                ))}
                            </Field>
                            <ErrorMessage name="salesTeam" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="supplementaryNotes">补充说明</label>
                            <Field as="textarea" name="supplementaryNotes" className="form-field" />
                            <ErrorMessage name="supplementaryNotes" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="supplementedLeadId">补充客资ID</label>
                            <Field as="select" name="supplementedLeadId" className="form-field"
                                onChange={e => {
                                    const supplementedLeadId = e.target.value;
                                    setFieldValue('supplementedLeadId', supplementedLeadId);
                                    setFieldValue('status', supplementedLeadId ? '已补' : '待补', false);
                                }}
                            >
                                <option value="">请选择补充客资</option>
                                {validCustomerLeads.map((lead) => (
                                    <option key={lead.id} value={lead.id}>
                                        {lead.name} (ID: {lead.id})
                                    </option>
                                ))}
                            </Field>
                            <ErrorMessage name="supplementedLeadId" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="status">状态</label>
                            <Field as="select" name="status" className="form-field">

                                <option value="待补" disabled>待补</option>
                                <option value="已补" disabled>已补</option>
                            </Field>
                            <ErrorMessage name="status" component="div" className="error-message" />
                        </div>

                        <button type="submit" disabled={isSubmitting} className="submit-button">
                            {isSubmitting ? '提交中...' : '提交'}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default PendingCustomerLeadForm;
