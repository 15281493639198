import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';
import './CustomerList.css';

const CustomerList = ({keycloak}) => {
    const [customers, setCustomers] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [editingCustomerId, setEditingCustomerId] = useState(null);
    const [editedCustomer, setEditedCustomer] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [inputPage, setInputPage] = useState(1);
    const location = useLocation();
    const customerIds = location.state?.customerIds || [];
    const handle = location.state?.handle || '';
    const selectedCustomerDetails = location.state?.selectedCustomerDetails;
    const [selectedCustomerIds, setSelectedCustomerIds] = useState(customerIds);
    const [selectedChannel, setSelectedChannel] = useState('');
    const [availableChannels, setAvailableChannels] = useState([]);
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState('');
    const [isUploadLead ,setIsUploadLead] = useState('');

    useEffect(() => {
      // 从 keycloak 中提取用户角色和可用的筛选选项
      const userGroups = keycloak.tokenParsed?.group || [];
      let role = '';
      if (userGroups.includes('/数据中心')) {
        role = 'dataCenter';
      } else if (userGroups.some(g => g.startsWith('/项目组/'))) {
        role = 'projectGroup';
      } else if (userGroups.some(g => g.includes('/小组长'))) {
        role = 'salesManager';
      } else if (userGroups.some(g => g.includes('/规划师'))) {
        role = 'planner';
      }
      setUserRole(role);
  
    }, [keycloak]);

    const fetchCustomers = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/customers/query', {
                params: {
                    page: page - 1,
                    searchTerm: searchTerm || undefined,
                    channel: selectedChannel || undefined,
                    isUploadLead: isUploadLead || undefined
                },
            });
            setCustomers(response.data.content || []);
            setTotalPages(response.data.totalPages);
            setTotalItems(response.data.totalElements);
            setInputPage(page);
    
            // 更新可用的渠道列表
            if (response.data.availableChannels) {
                setAvailableChannels(response.data.availableChannels);
            }
        } catch (error) {
            console.error('获取客户信息失败', error);
        }
    }, [page, searchTerm, selectedChannel, isUploadLead]);

    const fetchAvailableChannels = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/customers/available-channels');
            setAvailableChannels(response.data);
        } catch (error) {
            console.error('获取可用渠道失败', error);
        }
    }, []);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    useEffect(() => {
        fetchAvailableChannels();
    }, [fetchAvailableChannels]);

    const handleDelete = async (customerId) => {
        if (window.confirm('确认删除该客户？')) {
            try {
                await axiosInstance.delete(`/customers/${customerId}`);
                fetchCustomers();
            } catch (error) {
                console.error('删除客户时出错', error);
            }
        }
    };

    const handleEdit = (customerId) => {
        const customer = customers.find((c) => c.id === customerId);
        setEditingCustomerId(customerId);
        setEditedCustomer(customer);
    };

    const handleSave = async () => {
        try {
            await axiosInstance.put(`/customers/${editingCustomerId}`, editedCustomer);
            fetchCustomers();
            setEditingCustomerId(null);
        } catch (error) {
            console.error('保存修改时出错', error);
        }
    };

    const handleFieldChange = (field, value) => {
        const updatedCustomer = { ...editedCustomer, [field]: value };
        setEditedCustomer(updatedCustomer);
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const handleJumpToPage = () => {
        if (inputPage >= 1 && inputPage <= totalPages) {
            setPage(inputPage);
        } else {
            alert(`请输入有效的页码（1到${totalPages}）`);
        }
    };

    const handleCustomerSelect = (customerId) => {
        setSelectedCustomerIds((prevSelected) =>
            prevSelected.includes(customerId) ? prevSelected.filter(id => id !== customerId) : [...prevSelected, customerId]
        );
    };

    const handleAddToCustomerLeads = () => {
        handle === 'add' ? navigate('/customerLeadsList', { state: { selectedCustomerIds, selectedCustomerDetails } }) :
            navigate('/customerLeadsForm', { state: { selectedCustomerIds, selectedCustomerDetails } });
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        let startPage = Math.max(1, page - 4);
        let endPage = Math.min(totalPages, page + 5);

        if (endPage - startPage < 9) {
            startPage = Math.max(1, endPage - 9);
            endPage = Math.min(totalPages, startPage + 9);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={i === page ? 'active' : ''}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }
        return pageNumbers;
    };

    return (
        <div className="list-container">
            <h1>客户列表</h1>
            <div className="filter-container">
                <input
                    type="text"
                    placeholder="根据ID、身份证号、姓名、电话搜索"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <select
                    value={selectedChannel}
                    onChange={(e) => setSelectedChannel(e.target.value)}
                >
                    <option value="">所有渠道</option>
                    {availableChannels.map((channel) => (
                        <option key={channel} value={channel}>
                            {channel}
                        </option>
                    ))}
                </select>
                <select
                    value={isUploadLead}
                    onChange={(e) => setIsUploadLead(e.target.value)}
                >
                    <option value="">所有记录</option>              
                    <option value="true">已录入客资</option>
                    <option value="false">未录入客资</option>
                </select>
            </div>

            <div className="table-scrollable">
                <table className="customer-table">
                    <thead>
                        <tr>
                            {location.pathname === '/CustomerListLeadUpload' && (<th>选择</th>)}
                            <th>ID</th>
                            <th>身份证号</th>
                            <th>姓名</th>
                            <th>电话</th>
                            <th>渠道</th>
                            <th>上传者</th>
                            <th>上传时间</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customers.map((customer) => (
                            <tr key={customer.id} onClick={(e) => { e.stopPropagation(); if (!(customer.customerLeadId)) handleCustomerSelect(customer.id) }} style={{ cursor: 'pointer' }}>
                                {location.pathname === '/CustomerListLeadUpload' && (<td>
                                    <input
                                        type="checkbox"
                                        checked={selectedCustomerIds?.includes(customer.id)}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (!(customer.customerLeadId)) { // 仅在 customer_lead_id 为空时选中
                                                handleCustomerSelect(customer.id);
                                            }
                                        }}
                                        disabled={!!customer.customerLeadId} // 当 customer_lead_id 不为空时禁用复选框
                                    />
                                </td>)}
                                <td>{customer.id}</td>
                                <td>
                                    {editingCustomerId === customer.id ? (
                                        <input
                                            value={editedCustomer?.identityCard || ''}
                                            onChange={(e) => handleFieldChange('identityCard', e.target.value)}
                                            className="edit-input"
                                            onClick={e => e.stopPropagation()}
                                        />
                                    ) : (
                                        customer.identityCard
                                    )}
                                </td>
                                <td>
                                    {editingCustomerId === customer.id ? (
                                        <input
                                            value={editedCustomer?.name || ''}
                                            onChange={(e) => handleFieldChange('name', e.target.value)}
                                            className="edit-input"
                                            onClick={e => e.stopPropagation()}
                                        />
                                    ) : (
                                        customer.name
                                    )}
                                </td>
                                <td>
                                    {editingCustomerId === customer.id ? (
                                        <input
                                            value={editedCustomer?.phone || ''}
                                            onChange={(e) => handleFieldChange('phone', e.target.value)}
                                            className="edit-input"
                                            onClick={e => e.stopPropagation()}
                                        />
                                    ) : (
                                        customer.phone
                                    )}
                                </td>
                                <td>
                                    {editingCustomerId === customer.id ? (
                                        <input
                                            value={editedCustomer?.channel || ''}
                                            onChange={(e) => handleFieldChange('channel', e.target.value)}
                                            className="edit-input"
                                            onClick={e => e.stopPropagation()}
                                        />
                                    ) : (
                                        customer.channel
                                    )}
                                </td>
                                <td>
                                    {editingCustomerId === customer.id ? (
                                        <input
                                            value={editedCustomer?.uploader || ''}
                                            onChange={(e) => handleFieldChange('uploader', e.target.value)}
                                            className="edit-input"
                                            onClick={e => e.stopPropagation()}
                                        />
                                    ) : (
                                        customer.uploader
                                    )}
                                </td>
                                <td>
                                    {new Date(customer.uploadTime).toLocaleString()}
                                </td>
                                <td>
                                    {editingCustomerId === customer.id ? (
                                        <>
                                            <button onClick={(e) => {
                                                e.stopPropagation(); // 阻止事件冒泡
                                                handleSave();
                                            }} className="action-button">保存</button>
                                            <button onClick={(e) => {
                                                e.stopPropagation(); // 阻止事件冒泡
                                                setEditingCustomerId(null);
                                            }} className="action-button">取消</button>
                                        </>
                                    ) : (
                                        <>
                                            {userRole === 'dataCenter' && (<button onClick={(e) => {
                                                e.stopPropagation(); // 阻止事件冒泡
                                                handleEdit(customer.id);
                                            }} className="action-button">编辑</button>)}
                                            <button onClick={(e) => {
                                                e.stopPropagation(); // 阻止事件冒泡
                                                handleDelete(customer.id);
                                            }} className="action-button">删除</button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination-container">
            {location.pathname === '/CustomerListLeadUpload' && (<div className="add-to-leads">
                <button onClick={handleAddToCustomerLeads}>添加到客资记录</button>
            </div>)}
                <div className="pagination">
                    <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                        上一页
                    </button>
                    {renderPageNumbers()}
                    <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
                        下一页
                    </button>
                </div>
                <div className="pagination-info">
                    <span>共 {totalItems} 条记录, {totalPages} 页</span>
                    <div className="page-jump">
                        <input
                            type="number"
                            min="1"
                            max={totalPages}
                            value={inputPage}
                            onChange={(e) => setInputPage(Number(e.target.value) || 1)}
                        />
                        <button onClick={handleJumpToPage}>跳转</button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default CustomerList;
