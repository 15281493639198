const config = {
    development: {
      apiUrl: 'http://localhost:8084',
      salesTeamsGroupId: 'b7236ada-8c75-4f5a-972d-159ec3f3bed5',
      projectGroupsId: '0f76b6cf-3b8b-490a-af01-91a0c4b55b17',
      projectGroupName: '/项目组'
    },
    production: {
      apiUrl: 'https://xbackend.qinhegu.cn/login',
      anotherVar: 'prodValue',
    },
    test: {
      apiUrl: 'http://test.api.example.com',
      anotherVar: 'testValue',
    },
  };
  
export default config[process.env.NODE_ENV || 'development'];
  