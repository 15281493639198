// src/config/fieldConfig.js

// 性别选项
export const genderOptions = ["男", "女", "男/女"];

export const wxStateOptions = ["已加", "未加"];

// 收入资产选项
export const incomeAssetsOptions = ["200万以下", "200-500万", "500万以上"];

// 居住状态选项
export const residenceStatusOptions = [
  "独居",
  "夫妻同住",
  "子女同住",
  "其他养老机构在住",
  "跟亲戚一起住",
  "其他",
];

// 跟进状态选项
export const followUpStatusOptions = [
  "跟进中",
  "放弃",
  "回炉",
  "定金",
  "流失",
  "全款",
];

// 客户等级选项
export const customerLevelOptions = ["A", "B", "C"];

// 意向楼栋选项
export const buildingOptions = ["一号楼", "二号楼"];

// 客户抗性选项
export const customerResistanceOptions = ["价格", "位置", "服务", "其他"];

// 部门选项
export const departmentOptions = ["销售部", "市场部", "客服部", "运营部"];

// 项目组和来访途径动态联动
export const visitMethodConfig = {
  "项目一": ["视频号", "大众点评", "小红书", "知乎"],
  "项目二": ["长者朋友圈", "转介绍"],
  "项目三": ["会员转介绍"],
  "项目四": ["初新个资", "初新团客"],
  "非项目渠道": ["规划师自拓", "管理层转介绍", "其他"],
};

// 居住区域配置
export const residenceAreaConfig = {
  "上海市": [
    "浦东新区",
    "杨浦区",
    "徐汇区",
    "黄浦区",
    "闵行区",
    "青浦区",
    "普陀区",
    "虹口区",
    "静安区",
    "嘉定区",
    "宝山区",
    "长宁区",
    "金山区",
    "松江区",
    "奉贤区",
    "崇明区",
  ],
  "其他": ["其他区域"],
};

// 楼栋和卡种动态联动
export const buildingToCardTypeConfig = {
  "一号楼": ["VIP中套"],
  "二号楼": ["秋实欢享卡", "秋实尊享卡"],
};

// 卡种和户型动态联动
export const cardTypeToRoomTypeConfig = {
  "VIP中套": ["南中套", "北中套"],
  "秋实欢享卡": ["南中套", "北大套"],
  "秋实尊享卡": ["南中套", "北大套", "VIP定制"],
};

// 获取动态选项的方法
export const getVisitMethods = (projectGroup) =>
  visitMethodConfig[projectGroup] || [];
export const getResidenceAreas = (city) =>
  residenceAreaConfig[city] || [];
export const getCardTypes = (building) =>
  buildingToCardTypeConfig[building] || [];
export const getRoomTypes = (cardType) =>
  cardTypeToRoomTypeConfig[cardType] || [];
